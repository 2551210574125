var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-unused-expressions */
import React from 'react';
import { toastError, ToastMessage, toastSuccess, toastWarning } from '@samc/react-ui-core';
import { apiProvider } from '@samc/user-management-api';
import { Status } from '../../static/UserType';
import { getDisableUserContent } from '../../static/DisableUserContent';
export const baseUrl = `${window.location.origin}/_apis/user-management`;
export const onSubmit = (activeUsers, disableReason, isGlobal, requestInit, updateAction, refreshViewSet) => __awaiter(void 0, void 0, void 0, function* () {
    const { NOTIFICATIONS } = getDisableUserContent();
    const updatedUsers = activeUsers.map((u) => {
        const user = Object.assign(Object.assign({}, u), { UserId: u.primaryKey });
        delete user.UserID;
        delete user.primaryKey;
        delete user.OrderBy;
        return Object.assign(Object.assign({}, user), { InActivationReason: disableReason, StatusTypeId: Status.Inactive });
    });
    const showError = () => {
        toastError(React.createElement(ToastMessage, { title: NOTIFICATIONS.LABEL.DISABLE_USERS, message: NOTIFICATIONS.MESSAGE.DISABLE_USERS_FAIL }));
    };
    yield apiProvider
        .postData(`${baseUrl}/api/UserAction`, {
        payload: {
            isClientAdmin: !isGlobal,
            isGlobalAdmin: isGlobal,
            users: updatedUsers,
        },
    }, requestInit !== null && requestInit !== void 0 ? requestInit : {})
        .then((r) => {
        if (r.errorCount && r.errorCount !== 0) {
            showError();
        }
        else {
            toastSuccess(React.createElement(ToastMessage, { title: NOTIFICATIONS.LABEL.DISABLE_USERS, message: NOTIFICATIONS.MESSAGE.DISABLE_USERS_SUCCESS }));
        }
        refreshViewSet && setTimeout(refreshViewSet, 1000);
    })
        .catch(showError);
    updateAction(undefined);
});
export const warnNoActiveUsers = (updateAction, NOTIFICATIONS) => {
    setTimeout(updateAction.bind(undefined, undefined), 1000);
    toastWarning(React.createElement(ToastMessage, { title: NOTIFICATIONS.LABEL.DISABLE_USERS, message: NOTIFICATIONS.MESSAGE.DISABLE_USERS_NO_SELECTION }));
};
export const userAdhocListViewMembers = [
    {
        scalarExpression: '[StatusTypeId]',
        scalarDisplayName: 'StatusTypeId',
    },
    {
        scalarExpression: '[EmailAddress]',
        scalarDisplayName: 'EmailAddress',
    },
    {
        scalarExpression: '[LastName]',
        scalarDisplayName: 'LastName',
    },
    {
        scalarExpression: '[FirstName]',
        scalarDisplayName: 'FirstName',
    },
    {
        scalarExpression: '[CompanyName]',
        scalarDisplayName: 'CompanyName',
    },
    {
        scalarExpression: '[CreatedOn]',
        scalarDisplayName: 'CreatedOn',
    },
    {
        scalarExpression: '[CreatedBy]',
        scalarDisplayName: 'CreatedBy',
    },
    {
        scalarExpression: '[LastLogin]',
        scalarDisplayName: 'LastLogin',
    },
    {
        scalarExpression: '[UpdatedOn]',
        scalarDisplayName: 'UpdatedOn',
    },
    {
        scalarExpression: '[UpdatedBy]',
        scalarDisplayName: 'Updated By',
    },
    {
        scalarExpression: '[DisabledOn]',
        scalarDisplayName: 'DisabledOn',
    },
    {
        scalarExpression: '[InActivationReason]',
        scalarDisplayName: 'InActivationReason',
    },
    {
        scalarExpression: '[Roles]',
        scalarDisplayName: 'Roles',
    },
    {
        scalarExpression: '[ClientName]',
        scalarDisplayName: 'ClientName',
    },
];
export const globalUserAdhocListViewMembers = [
    {
        scalarExpression: '[StatusTypeId]',
        scalarDisplayName: 'StatusTypeId',
    },
    {
        scalarExpression: '[EmailAddress]',
        scalarDisplayName: 'EmailAddress',
    },
    {
        scalarExpression: '[LastName]',
        scalarDisplayName: 'LastName',
    },
    {
        scalarExpression: '[FirstName]',
        scalarDisplayName: 'FirstName',
    },
    {
        scalarExpression: '[CreatedOn]',
        scalarDisplayName: 'CreatedOn',
    },
    {
        scalarExpression: '[CreatedName]',
        scalarDisplayName: 'CreatedName',
    },
    {
        scalarExpression: '[LastLogin]',
        scalarDisplayName: 'LastLogin',
    },
    {
        scalarExpression: '[UpdatedOn]',
        scalarDisplayName: 'UpdatedOn',
    },
    {
        scalarExpression: '[UpdatedName]',
        scalarDisplayName: 'Updated By',
    },
    {
        scalarExpression: '[DisabledOn]',
        scalarDisplayName: 'DisabledOn',
    },
    {
        scalarExpression: '[InActivationReason]',
        scalarDisplayName: 'InActivationReason',
    },
];
export default { userAdhocListViewMembers, globalUserAdhocListViewMembers, onSubmit, warnNoActiveUsers };
