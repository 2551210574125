import React from 'react';
import { useApplicationContext } from '@samc/screen-config-api';
import { useApiContext } from '@samc/user-management-api';
import { getReactivateUserContent } from '../../static/ReactivateUserContent';
import { Status, ViewID } from '../../static/UserType';
import { reactivateSelectedUsers, warnNoInActiveUsers, errorNotAuthorized } from './ReactivateUsers.utils';
const ReactivateUsers = ({ action, users, updateAction, refreshViewSet }) => {
    const { viewId } = action;
    const api = useApiContext();
    const { currentUser, Entitlements } = useApplicationContext();
    React.useEffect(() => {
        const isGlobal = viewId === ViewID.ReactivateGlobalUsers;
        const { NOTIFICATIONS } = getReactivateUserContent(users.length > 1);
        const inactiveUsers = users.filter(({ StatusTypeId }) => (StatusTypeId === null || StatusTypeId === void 0 ? void 0 : StatusTypeId.toLowerCase()) === Status.Inactive.toLowerCase());
        if (!currentUser.hasEntitlement(Entitlements.DisableUsers)) {
            errorNotAuthorized(updateAction, NOTIFICATIONS);
        }
        else if (inactiveUsers.length > 0) {
            reactivateSelectedUsers(inactiveUsers, isGlobal, api.requestInit, updateAction, refreshViewSet);
        }
        else {
            warnNoInActiveUsers(updateAction, NOTIFICATIONS);
        }
    }, [users, viewId, api.requestInit, updateAction, refreshViewSet, currentUser, Entitlements.DisableUsers]);
    return React.createElement(React.Fragment, null);
};
export default ReactivateUsers;
