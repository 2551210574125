var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useQBQuery } from '@samc/screen-config-api';
import { getResetPasswordUserContent } from '../../static/ResetPasswordUserContent';
import ResetPasswordUsers from './ResetPasswordUsers';
import { globalUserAdhocListViewMembers, warnVMSUsers } from './ResetPasswordUsers.utils';
const ResetUserPasswordModalWrapper = ({ users, updateAction, refreshViewSet }) => {
    const { NOTIFICATIONS } = React.useMemo(() => getResetPasswordUserContent(), [users]);
    const [userData, setUserData] = React.useState([]);
    const qbQuery = useQBQuery();
    const ids = React.useMemo(() => users.map((x) => `'${x.UserId}'`).join(','), [users]);
    React.useEffect(() => {
        function fetchQbData() {
            return __awaiter(this, void 0, void 0, function* () {
                const loadViewSetData = () => __awaiter(this, void 0, void 0, function* () {
                    return qbQuery({
                        domainId: 'GlobalUser',
                        primaryViewId: '-1',
                        filterIds: [],
                        paging: { start: 0, stop: 1000 },
                        sorting: {},
                        adhocListViewMembers: globalUserAdhocListViewMembers,
                        summaryMode: false,
                        adhocFilter: {
                            filterName: 'adhoc',
                            advancedInd: true,
                            expressionLang: 'Centric',
                            advancedExpression: `([UserId] IN (${ids}))`,
                        },
                    });
                });
                if (users.length > 0) {
                    const result = yield loadViewSetData();
                    if (result.Data &&
                        result.Data.length &&
                        result.Data.some(({ ProviderName }) => ProviderName === 'OKTA')) {
                        setUserData([...result.Data]);
                    }
                    else
                        warnVMSUsers(updateAction, NOTIFICATIONS);
                }
                else
                    warnVMSUsers(updateAction, NOTIFICATIONS);
            });
        }
        fetchQbData();
    }, [NOTIFICATIONS, qbQuery, updateAction]);
    return userData.length ? (React.createElement(ResetPasswordUsers, { users: userData, updateAction: updateAction, refreshViewSet: refreshViewSet })) : (React.createElement(React.Fragment, null));
};
export default ResetUserPasswordModalWrapper;
