import { CommandButton } from '@fluentui/react';
import { ConfirmationDialog, toastError, ToastMessage, toastSuccess, toastWarning } from '@samc/react-ui-core';
import { ColumnConfigurations, GFCCellRendererParams } from '@samc/react-ui-grid';
import { ViewSetAction, useApplicationContext } from '@samc/screen-config-api';
import React from 'react';
import { apiProvider, useApiContext } from '@samc/user-management-api';
import { baseUrl } from '@samc/user-management-core/lib/organisms/DisableUsers/DisableUsers.utils';
import { Contact, ViewID } from '@samc/user-management-core/lib/static/UserType';
import './CustomColumns.scss';
import { CustomCellRendererProps } from 'ag-grid-react';

interface CustomColumnRendererType {
  customColumns: ColumnConfigurations[];
}

interface ConfirmationProps {
  data: Contact[] | null;
  onDismiss: () => void;
  updateAction: (action: ViewSetAction | undefined) => void;
  refreshViewSet: () => void;
  action?: ViewSetAction;
}
export const ConfirmationModal = (props: ConfirmationProps): React.ReactElement => {
  const { data, onDismiss, updateAction, refreshViewSet, action } = props;
  const api = useApiContext();
  const { currentUser, Entitlements } = useApplicationContext();
  if (data?.length === 0) {
    toastWarning(
      <ToastMessage
        title={action?.viewId === ViewID.ApproveUsers ? 'Approve Users' : 'Reject Users'}
        message="No user selected"
      />,
    );
    updateAction(undefined);
    return <></>;
  }
  const onRequest = async (doSave: boolean): Promise<void> => {
    if (!doSave) {
      onDismiss();
      updateAction(undefined);
      return;
    }
    if (!currentUser.hasEntitlement(Entitlements.CreateUsers)) {
      toastError(
        <ToastMessage
          title="Approve/Reject Users"
          message="You are not authorized to perform this action. Please contact your administrator if you believe this is an error."
        />,
      );
      onDismiss();
      updateAction(undefined);
      return;
    }
    onDismiss();
    await apiProvider
      .postData(
        `${baseUrl}/api/User`,
        {
          addTasks: data,
          updateTasks: [],
          deleteTasks: [],
        },
        api.requestInit ?? {},
      )
      .then((r) => {
        if (r.errorCount && r.errorCount !== 0) {
          toastError(<ToastMessage title="Error" message="Something went wrong" />);
        } else if (data && data[0].StatusTypeId === 'Approved') {
          toastSuccess(<ToastMessage title="Successful" message="User(s) Approved" />);
        } else {
          toastSuccess(<ToastMessage title="Successful" message="User(s) Rejected" />);
        }
        setTimeout(refreshViewSet, 1000);
      })
      .catch((error) => {
        toastError(<ToastMessage title="Error" message={error} />);
      });
    updateAction(undefined);
  };
  return (
    <ConfirmationDialog
      giveAnswer={onRequest}
      title="Confirmation"
      confirmText="Yes"
      cancelText="No"
      detail={
        data && data[0].StatusTypeId === 'Approved'
          ? 'Are you sure you want to approve the selected user(s)? Once request is approved, it cannot be rejected.'
          : 'Are you sure you want to reject the selected user(s)? Once request is rejected, it cannot be approved without a new request.'
      }
    />
  );
};

interface RequestIconProps
  extends Pick<GFCCellRendererParams, 'node' | 'data' | 'deleteRows'>,
    CustomCellRendererProps {
  setShowConfigModal: (showConfigModal: boolean) => void;
  setConfigData: (configData: Contact[]) => void;
}
// eslint-disable-next-line react/display-name
const UserRequestIconRenderer = React.forwardRef((props: RequestIconProps, ref): React.ReactElement => {
  const { data, setShowConfigModal, setConfigData, node } = props;
  const user: Contact = {
    StatusTypeId: data === undefined ? '' : data.StatusTypeId,
    GlobalUserRequestId: data === undefined ? '' : data.GlobalUserRequestId,
    emailAddress: data === undefined ? '' : data.emailAddress,
    InitiallyRequestedBy: data === undefined ? '' : data.InitiallyRequestedBy,
    RequestDateTime: data === undefined ? '' : data.RequestDateTime,
    CompanyName: data === undefined ? '' : data.CompanyName,
    ContactName: data === undefined ? '' : data.ContactName,
    ClientName: data === undefined ? '' : data.ClientName,
    firstName: data === undefined ? '' : data.firstName,
    lastName: data === undefined ? '' : data.lastName,
  };

  React.useImperativeHandle(
    ref,
    () => ({
      refresh: (): boolean => {
        return true;
      },
    }),
    [],
  );

  const approveOnClick = (): void => {
    user.StatusTypeId = 'Approved';
    const contacts: Contact[] = [];
    contacts.push(user);
    setConfigData(contacts);
    setShowConfigModal(true);
  };

  const rejectOnClick = (): void => {
    user.StatusTypeId = 'Rejected';
    const contacts: Contact[] = [];
    contacts.push(user);
    setConfigData(contacts);
    setShowConfigModal(true);
  };

  if (node.rowPinned === 'bottom') {
    return <></>;
  }

  return (
    <>
      <CommandButton onClick={approveOnClick} iconProps={{ iconName: 'Completed' }} style={{ padding: '0px' }} />
      <CommandButton onClick={rejectOnClick} iconProps={{ iconName: 'ErrorBadge' }} />
    </>
  );
});

const GetCustomColumns = ({
  setShowConfigModal,
  setConfigData,
}: Pick<RequestIconProps, 'setShowConfigModal' | 'setConfigData'>): CustomColumnRendererType => ({
  customColumns: [
    {
      index: 7,
      headerName: 'Actions',
      gridSelector: 'GlobalNewUserRequest',
      field: '',
      displayNameRule: ``,
      cellRenderer: (params) => (
        <UserRequestIconRenderer {...params} setConfigData={setConfigData} setShowConfigModal={setShowConfigModal} />
      ),
      filter: false,
      sortable: false,
    },
    {
      index: 8,
      headerName: 'Actions',
      gridSelector: 'GlobalReactivateRequest',
      field: '',
      displayNameRule: ``,
      cellRenderer: (params) => (
        <UserRequestIconRenderer {...params} setConfigData={setConfigData} setShowConfigModal={setShowConfigModal} />
      ),
      filter: false,
      sortable: false,
    },
  ],
});

export default GetCustomColumns;
