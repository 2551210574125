/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { GridScreenWrapper } from '@samc/screen-config-core/lib/organisms/GridScreenWrapper/GridScreenWrapper';
import { getSelectedFilterIds, saveSelectedFilter, } from '@samc/screen-config-core/lib/organisms/GridScreen/GridScreen.utilities';
import { onSetState } from '@samc/screen-config-core/lib/utils/State/StateUtils';
import { useHeaderContext } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { ServiceDrivenViewSet } from '@samc/screen-config-core';
import { useStyletron } from 'styletron-react';
import { ClassNameBuilder } from '@samc/react-ui-core';
const customTabs = ['USER_BidTeams'];
const CustomTabRenderer = (params) => {
    const { tabId, filters, baselineFilterExpression, defaultData, parentScope, onSelectionChanges, tabs } = params;
    const [viewId, setViewId] = React.useState('USER_BidTeams');
    const { selectedTabId } = useHeaderContext();
    const [css] = useStyletron();
    const selectedViewTab = selectedTabId ? tabs[selectedTabId] : undefined;
    const { baselineFilterExpression: selectedTabBaselineFilterExpression } = selectedViewTab !== null && selectedViewTab !== void 0 ? selectedViewTab : {};
    const scope = parentScope ? `${parentScope}_${tabId}` : undefined;
    const mergedFilters = React.useMemo(() => [...(filters !== null && filters !== void 0 ? filters : []), baselineFilterExpression, selectedTabBaselineFilterExpression].filter((exp) => typeof exp === 'string'), [baselineFilterExpression, filters, selectedTabBaselineFilterExpression]);
    const [selectedFilterIds, _setSelectedFilterIds] = React.useState(scope ? getSelectedFilterIds({ scope, defaultFilterIds: [] }) : []);
    React.useLayoutEffect(() => {
        let newFilterIds = [];
        if (scope)
            newFilterIds = getSelectedFilterIds({ scope, defaultFilterIds: [] });
        _setSelectedFilterIds(newFilterIds);
    }, [scope, tabId]);
    const setSelectedFilterIds = React.useCallback((action) => {
        onSetState(_setSelectedFilterIds, action, (newFilterIds) => {
            if (scope)
                saveSelectedFilter({
                    scope,
                    selectedFilterIds: newFilterIds,
                    defaultFilterIds: [],
                });
        });
    }, [scope]);
    switch (tabId) {
        case 'USER_BidTeams':
            return (React.createElement("div", { className: css({ flex: 1, height: '100%', display: 'flex', flexDirection: 'column' }) },
                React.createElement("label", { className: ClassNameBuilder('info', css({ flexShrink: 0, padding: '12px 12px 0px 12px' })) }, "*Each Bidding Valuer can have a Bid Support Team. When a Bid Request is made to a Bidding Valuer, all users on their support team will be assigned to the Bid Request. Set up the Bid Support Team below."),
                React.createElement(GridScreenWrapper, { listViewId: viewId, setListViewId: setViewId, filters: mergedFilters, selectedFilterIds: selectedFilterIds, setSelectedFilterIds: setSelectedFilterIds, defaultData: defaultData, onSelectionChanges: onSelectionChanges, gridDisplayOptions: { suppressAddRowButton: false, suppressEditToggle: false }, ServiceDrivenViewSet: ServiceDrivenViewSet })));
        default:
            return React.createElement(React.Fragment, null);
    }
};
export const renderCustomTab = (params) => {
    if (!customTabs.includes(params.tabId))
        return undefined;
    return React.createElement(CustomTabRenderer, Object.assign({}, params));
};
export default renderCustomTab;
