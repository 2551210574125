import React from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { BrowserHistory, HashHistory } from 'history';
import { RootWithChecks } from './root.component.checks';
import '@samc/vmsnext-querybuilder/dist/esm/index.css';

export interface SingleSpaProps {
  // eslint-disable-next-line react/no-unused-prop-types
  styletronEngine: Styletron;
  browserHistory: BrowserHistory;
  hashHistory: HashHistory;
}

export const Root = (props: SingleSpaProps): React.ReactElement | null => <RootWithChecks {...props} />;

export default Root;
