var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useApplicationContext } from '@samc/screen-config-api';
import { Modal } from '@fluentui/react';
import { SitusHeader } from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import { Button, ControlBar, ToastMessage, toastError } from '@samc/react-ui-core';
import { useApiContext } from '@samc/user-management-api';
import { getResetPasswordUserContent } from '../../static/ResetPasswordUserContent';
import { resetPasswordSelectedUsers } from './ResetPasswordUsers.utils';
const ResetPasswordUsers = ({ users, updateAction, refreshViewSet }) => {
    const [showModal, toggleModal] = React.useState(true);
    const oktaUsers = [];
    const nonOktaUsers = [];
    const { currentUser, Entitlements } = useApplicationContext();
    users.forEach((u) => {
        if (u.ProviderName === 'OKTA') {
            oktaUsers.push(u);
        }
        else {
            nonOktaUsers.push(u);
        }
    });
    const closeModal = () => {
        toggleModal(false);
        updateAction(undefined);
    };
    const { BUTTONS, LABELS } = React.useMemo(() => getResetPasswordUserContent(), []);
    const api = useApiContext();
    if (!currentUser.hasEntitlement(Entitlements.UpdateUsers)) {
        toastError(React.createElement(ToastMessage, { title: "Reset Password", message: "You are not authorized to perform this action. Please contact your administrator if you believe this is an error." }));
        updateAction(undefined);
    }
    const onResetClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (oktaUsers.length > 0) {
            resetPasswordSelectedUsers(oktaUsers, api.requestInit, updateAction, refreshViewSet);
        }
    });
    const getEmailList = (userList, listLabel) => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "modal-text" },
            listLabel,
            " (",
            userList.length,
            ")"),
        React.createElement("ul", { className: "user-list" }, userList.map(({ EmailAddress }) => (React.createElement("li", { key: EmailAddress }, EmailAddress))))));
    return (React.createElement(Modal, { isOpen: showModal, onDismiss: closeModal, isBlocking: false, className: "userModals" },
        React.createElement("div", { className: "ag-theme-balham ag-theme-centricity disable-user-modal", style: { display: 'block', width: '66vw' } },
            React.createElement(SitusHeader, { title: LABELS.RESET_USERS_TITLE },
                React.createElement(ControlBar, { cancel: closeModal, cancelDisabled: false, additionalControls: React.createElement(Button, { text: BUTTONS.RESET_USERS, onClick: onResetClick }) })),
            React.createElement("div", { className: "disable-modal-content" },
                getEmailList(users, LABELS.SELECTED_LIST),
                oktaUsers.length > 0 && getEmailList(oktaUsers, LABELS.ACTIVE_LIST),
                nonOktaUsers.length > 0 && getEmailList(nonOktaUsers, LABELS.ALREADY_DISABLED_LIST),
                React.createElement("div", null)))));
};
export default ResetPasswordUsers;
