/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { UnsavedChangesDialog, toastError, ToastMessage } from '@samc/react-ui-core';
import { EditingProvider } from '@samc/react-ui-grid';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { RoutingWrapper, useHeaderContext, useRouterBlockerAndPromptForUnsavedChanges } from '@samc/react-ui-history';
import { ViewSetAction, useApplicationContext } from '@samc/screen-config-api';
import { ServiceDrivenViewSet, ViewSetSubtabChangedEvent, getTabRedirectString } from '@samc/screen-config-core';
import { ActionOverrideContextProvider } from '@samc/screen-config-core/lib/contexts/ActionOverrideContext/ActionOverrideContext';
import { Contact, UserType, ViewID } from '@samc/user-management-core/lib/static/UserType';
import ChangeEmailWrapper from '@samc/user-management-core/lib/organisms/ChangeEmail/ChangeEmailWrapper';
import DisableUserModalWrapper from '@samc/user-management-core/lib/organisms/DisableUsers/DisableUserModalWrapper';
import DisableIndividualUser from '@samc/user-management-core/lib/organisms/DisableUsers/DisableIndividualUser';
import ReactivateUsers from '@samc/user-management-core/lib/organisms/ReactivateUsers/ReactivateUsers';
import ReactivateIndividualUser from '@samc/user-management-core/lib/organisms/ReactivateUsers/ReactivateIndividualUser';
import UnlockUsers from '@samc/user-management-core/lib/organisms/UnlockUsers/UnlockUsers';
import UnlockIndividualUser from '@samc/user-management-core/lib/organisms/UnlockUsers/UnlockIndividualUser';
import ResetUserPasswordModalWrapper from '@samc/user-management-core/lib/organisms/ResetUserPassword/ResetUserPasswordModalWrapper';
import ResetPasswordIndividualUser from '@samc/user-management-core/lib/organisms/ResetUserPassword/ResetPasswordIndividualUser';
import UpdateRoles from '@samc/user-management-core/lib/organisms/UpdateRoles/UpdateRoles';
import { renderCustomTab } from '@samc/user-management-core/lib/organisms/CustomTabRenderer/CustomTabRenderer';
import GetCustomColumns, { ConfirmationModal } from './CustomColumns';
import { overridenViewids } from './overrides';

interface ViewSetMainProps {
  triggeredAction: ViewSetAction | undefined;
  updateAction: React.Dispatch<ViewSetAction | undefined>;
  showConfigModal?: boolean;
  setShowConfigModal?: (showConfigModal: boolean) => void;
  configData?: Contact[] | null;
  userId?: string;
  showAddModal?: string;
  setShowAddModal?: (modalId: string | undefined) => void;
  onTabChanged?: (newId: string) => void;
  onSubtabChanged?: (ev: ViewSetSubtabChangedEvent) => void;
  tabId?: string;
  subtabId?: string;
}

export const ViewSetMain = (props: ViewSetMainProps): React.ReactElement => {
  const {
    triggeredAction,
    updateAction,
    showConfigModal,
    configData,
    setShowConfigModal,
    userId,
    showAddModal,
    setShowAddModal,
    onTabChanged,
    tabId,
    subtabId,
    onSubtabChanged,
  } = props;

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { viewId } = triggeredAction ?? { viewId: '' };
  const { currentUser, Entitlements } = useApplicationContext();

  React.useEffect(() => {
    if (setShowAddModal) {
      setShowAddModal(showAddModal);
    }
  }, [showAddModal, setShowAddModal]);

  useRouterBlockerAndPromptForUnsavedChanges();
  const [selectedRows, onSelectionChanged] = React.useState<Record<string, unknown>[]>([]);
  const [showBulkConfigModal, onShowBulkConfigModal] = React.useState<boolean>(false);
  const [viewsetKey, updateViewsetKey] = React.useState<number>(1);
  const handleSetPrimaryKey = React.useCallback(
    (value: string | undefined): void => {
      const params = new URLSearchParams(location.search);
      if (value) {
        params.set('primaryKeyValue', value);
        navigate(`${location.pathname}?${params.toString()}`);
      }
    },
    [location.pathname, location.search, navigate],
  );

  React.useEffect(() => {
    onSelectionChanged([]); // clear any row selections when viewsetid changes
  }, [id]);

  React.useEffect(() => {
    if (viewId === ViewID.ApproveUsers || viewId === ViewID.RejectUsers)
      if (!currentUser.hasEntitlement(Entitlements.CreateUsers))
        toastError(
          <ToastMessage
            title="Approve/Reject Users"
            message="You are not authorized to perform this action. Please contact your administrator if you believe this is an error."
          />,
        );
      else {
        onShowBulkConfigModal(true);
      }
  }, [viewId]);

  const refreshViewSet = React.useCallback(() => {
    onSelectionChanged([]);
    return updateViewsetKey(viewsetKey + 1);
  }, [viewsetKey]);

  const onDismiss = React.useCallback(() => {
    if (setShowConfigModal) {
      setShowConfigModal(false);
      onShowBulkConfigModal(false);
    }
  }, [showConfigModal]);

  const BulkApprove = React.useMemo(
    () =>
      selectedRows.map(
        (u): Contact => ({
          StatusTypeId: 'Approved',
          GlobalUserRequestId: String(u.GlobalUserRequestId),
          emailAddress: String(u.emailAddress),
          InitiallyRequestedBy: String(u.InitiallyRequestedBy),
          RequestDateTime: String(u.RequestDateTime),
          CompanyName: String(u.CompanyName),
          ContactName: String(u.ContactName),
          ClientName: String(u.ClientName),
          firstName: String(u.firstName),
          lastName: String(u.lastName),
        }),
      ),
    [selectedRows],
  );

  const BulkReject = React.useMemo(
    () =>
      selectedRows.map(
        (u): Contact => ({
          StatusTypeId: 'Rejected',
          GlobalUserRequestId: String(u.GlobalUserRequestId),
          emailAddress: String(u.emailAddress),
          InitiallyRequestedBy: String(u.InitiallyRequestedBy),
          RequestDateTime: String(u.RequestDateTime),
          CompanyName: String(u.CompanyName),
          ContactName: String(u.ContactName),
          ClientName: String(u.ClientName),
          firstName: String(u.firstName),
          lastName: String(u.lastName),
        }),
      ),
    [selectedRows],
  );

  return (
    <>
      <div key={viewsetKey} style={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto' }}>
        {id ? (
          // key here is set this way so that when the porfolio filter is changed, it will recreate/reload the data
          <ServiceDrivenViewSet
            onSelectionChanges={onSelectionChanged}
            key={id}
            viewSetId={id}
            initialSelectedTabId={tabId}
            onTabChanged={onTabChanged}
            primaryKeyValue={userId}
            tabRenderer={renderCustomTab}
            setPrimaryKeyValue={handleSetPrimaryKey}
            onSubtabChanged={onSubtabChanged}
            initialSelectedSubtabId={subtabId}
          />
        ) : (
          <div>Please specify a ViewSet Id.</div>
        )}
        {triggeredAction && (
          <>
            {(viewId === ViewID.DisableUsers || viewId === ViewID.DisableGlobalUsers) && (
              <DisableUserModalWrapper
                action={triggeredAction}
                users={selectedRows as UserType[]}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
              />
            )}

            {(viewId === ViewID.ReactivateUsers || viewId === ViewID.ReactivateGlobalUsers) && (
              <ReactivateUsers
                action={triggeredAction}
                users={selectedRows as UserType[]}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
              />
            )}
            {(viewId === ViewID.UnlockUsers || viewId === ViewID.UnlockGlobalUsers) && (
              <UnlockUsers
                action={triggeredAction}
                users={selectedRows as UserType[]}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
              />
            )}

            {viewId === ViewID.UserPassword && (
              <ResetUserPasswordModalWrapper
                users={selectedRows as UserType[]}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
              />
            )}

            {(viewId === ViewID.DisableUser || viewId === ViewID.DisableGlobalUser) && (
              <DisableIndividualUser
                action={triggeredAction}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
              />
            )}

            {(viewId === ViewID.ReactivateUser || viewId === ViewID.ReactivateGlobalUser) && (
              <ReactivateIndividualUser
                action={triggeredAction}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
              />
            )}
            {(viewId === ViewID.UnlockUser || viewId === ViewID.UnlockGlobalUser) && (
              <UnlockIndividualUser
                action={triggeredAction}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
              />
            )}
            {(viewId === ViewID.IdentityUserPassword || viewId === ViewID.NonIdentityUserPassword) && (
              <ResetPasswordIndividualUser
                action={triggeredAction}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
                userId={userId}
              />
            )}

            {viewId === ViewID.ChangeEmail && (
              <ChangeEmailWrapper
                action={triggeredAction}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
              />
            )}

            {viewId === ViewID.AddRoles && (
              <UpdateRoles
                users={selectedRows}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
                action={triggeredAction}
              />
            )}

            {viewId === ViewID.RemoveRoles && (
              <UpdateRoles
                users={selectedRows}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
                action={triggeredAction}
              />
            )}

            {viewId === ViewID.ApproveUsers && showBulkConfigModal && (
              <ConfirmationModal
                data={BulkApprove}
                onDismiss={onDismiss}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
                action={triggeredAction}
              />
            )}

            {viewId === ViewID.RejectUsers && showBulkConfigModal && (
              <ConfirmationModal
                data={BulkReject}
                onDismiss={onDismiss}
                updateAction={updateAction}
                refreshViewSet={refreshViewSet}
                action={triggeredAction}
              />
            )}
          </>
        )}
      </div>
      {showConfigModal && (
        <ConfirmationModal
          data={configData || null}
          onDismiss={onDismiss}
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
        />
      )}
    </>
  );
};
export const ViewSetPage = (): React.ReactElement => {
  const originalLocation = useLocation();
  const { tabId: urlTabId, subtabId: urlSubtabId } = useParams();
  const { tabs } = useHeaderContext();

  const navigate = useNavigate();

  const [showConfigModal, setShowConfigModal] = React.useState<boolean>(false);
  const [configData, setConfigData] = React.useState<Contact[]>();
  const customColumns = React.useMemo(
    () => GetCustomColumns({ setShowConfigModal, setConfigData }),
    [setShowConfigModal, setConfigData],
  );

  const [triggeredAction, updateAction] = React.useState<ViewSetAction>();

  const onTabIdChanged = React.useCallback(
    (newId: string, userRequested?: boolean) => {
      if (newId === urlTabId) return; // do nothing if id doesn't change

      navigate(
        getTabRedirectString({
          originalLocation,
          oldTabId: urlTabId,
          oldSubtabId: urlSubtabId,
          newTabId: newId,
          newSubtabId: undefined, // should clear it on tab change
        }),
        { replace: !userRequested },
      );
    },
    [navigate, originalLocation, urlSubtabId, urlTabId],
  );

  const onSubtabIdChanged = React.useCallback(
    (ev: ViewSetSubtabChangedEvent) => {
      const { newId, userRequested } = ev;

      if (newId === urlSubtabId) return; // do nothing if id doesn't change

      navigate(
        getTabRedirectString({
          originalLocation,
          oldTabId: urlTabId,
          oldSubtabId: urlSubtabId,
          newTabId: urlTabId,
          newSubtabId: newId,
        }),
        { replace: !userRequested },
      );
    },
    [navigate, originalLocation, urlSubtabId, urlTabId],
  );

  const onTabIndexChanged = React.useCallback(
    (newIndex: number, userRequested: boolean) => {
      if (!tabs) return;

      const tab = tabs[newIndex];
      if (!tab) return;

      onTabIdChanged(tab.id, userRequested);
    },
    [onTabIdChanged, tabs],
  );

  return (
    <EditingProvider value={customColumns}>
      <ActionOverrideContextProvider
        value={overridenViewids.reduce(
          (o, v) => ({ ...o, [v]: (item: ViewSetAction): void => updateAction(item) }),
          {},
        )}
      >
        <RoutingWrapper onTabChanged={onTabIndexChanged}>
          <ViewSetMain
            onTabChanged={onTabIdChanged}
            triggeredAction={triggeredAction}
            updateAction={updateAction}
            showConfigModal={showConfigModal}
            configData={configData}
            setShowConfigModal={setShowConfigModal}
            tabId={urlTabId}
            onSubtabChanged={onSubtabIdChanged}
            subtabId={urlSubtabId}
          />
        </RoutingWrapper>
      </ActionOverrideContextProvider>
    </EditingProvider>
  );
};

export default ViewSetPage;
