export const content = {
    BTN_LABEL: {
        SAVE: 'Save',
    },
    LABELS: {
        TITLE: 'Change Email Address',
        CURRENT_EMAIL: 'Current Email Address',
        NEW_EMAIL: '*New Email Address',
        CONFIRM_EMAIL: '*Confirm New Email Address',
        CHANGE_REASON: '*Reason for Change',
        CONFIRM_CHANGE: `A user’s email address is his login for VMS Next. Confirm the user did not change companies. Changing companies requires a new user account.`,
    },
    MESSAGES: {
        CHANGE_EMAIL_LABEL: 'Change Email',
        GENERIC_ERROR: 'Something went wrong.',
        EMAIL_CHANGE_SUCCESS: 'Email Changed Successfully.',
        SAME_EMAIL_ERROR: 'The New Email Address and the Current Email Address are the same.',
        EMAIL_EXISTS_ERROR: 'This email address already exists for another user. It cannot be used again.',
        REQUIRED_EMAIL_ERROR: 'This field is required.',
        TOOLTIP_SAVE_ERROR: 'Save cannot be performed until errors are cleared.',
        EMAIL_NOT_MATCHING: 'The New Email Address and the Confirm New Email Address do not match.',
        EMAIL_NOT_VALID: 'New Email Address is not valid.',
        CHANGE_REASON_LENGTH_LIMIT: 'Email change reason cannot exceed 1000 characters.',
    },
    FORMS: {
        FORM_ID: 'Change Email',
        FORM_NAME: 'ChangeEmail Form',
        INVALID: 'Invalid',
        ERROR: 'Error',
    },
    REASONS: [
        ['Last Name Change', `The user's last name is legally updated. As a result, the email address is also updated.`],
        ['Typo', `The users email address was accidentally entered incorrectly in the system. It's just being corrected.`],
    ],
};
export default {
    content,
};
