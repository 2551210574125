export const adhocListViewMembers = [
    {
        scalarExpression: '[StatusTypeId]',
        scalarDisplayName: 'StatusTypeId',
    },
    {
        scalarExpression: '[EmailAddress]',
        scalarDisplayName: 'EmailAddress',
    },
    {
        scalarExpression: '[LastName]',
        scalarDisplayName: 'LastName',
    },
    {
        scalarExpression: '[FirstName]',
        scalarDisplayName: 'FirstName',
    },
    {
        scalarExpression: '[CompanyName]',
        scalarDisplayName: 'CompanyName',
    },
    {
        scalarExpression: '[UpdatedOn]',
        scalarDisplayName: 'UpdatedOn',
    },
    {
        scalarExpression: '[UpdatedBy]',
        scalarDisplayName: 'UpdatedBy',
    },
    {
        scalarExpression: '[CreatedOn]',
        scalarDisplayName: 'CreatedOn',
    },
    {
        scalarExpression: '[CreatedBy]',
        scalarDisplayName: 'CreatedBy',
    },
    {
        scalarExpression: '[LastLogin]',
        scalarDisplayName: 'LastLogin',
    },
    {
        scalarExpression: '[DisabledOn]',
        scalarDisplayName: 'DisabledOn',
    },
    {
        scalarExpression: '[InActivationReason]',
        scalarDisplayName: 'InActivationReason',
    },
    {
        scalarExpression: '[UserId]',
        scalarDisplayName: 'UserId',
    },
];
export default { adhocListViewMembers };
