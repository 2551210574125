var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-unused-expressions */
import React from 'react';
import { toastError, ToastMessage, toastSuccess, toastWarning } from '@samc/react-ui-core';
import { apiProvider } from '@samc/user-management-api';
import { Status } from '../../static/UserType';
import { getReactivateUserContent } from '../../static/ReactivateUserContent';
const baseUrl = `${window.location.origin}/_apis/user-management`;
export const reactivateSelectedUsers = (inactiveUsers, isGlobal, requestInit, updateAction, refreshViewSet) => __awaiter(void 0, void 0, void 0, function* () {
    const { NOTIFICATIONS } = getReactivateUserContent(inactiveUsers.length > 1);
    const updatedUsers = inactiveUsers.map((u) => {
        const user = Object.assign({}, u);
        delete user.UserID;
        delete user.primaryKey;
        delete user.OrderBy;
        delete user.NewEmailAddress;
        delete user.ConfirmNewEmailAddress;
        delete user.companyNotChanged;
        delete user.EmailAddressChangeReason;
        return Object.assign(Object.assign({}, user), { InActivationReason: '', StatusTypeId: Status.Active });
    });
    const showError = (msg = NOTIFICATIONS.MESSAGE.REACTIVATE_USERS_FAIL) => {
        toastError(React.createElement(ToastMessage, { title: NOTIFICATIONS.LABEL.REACTIVATE_USERS, message: msg }));
    };
    yield apiProvider
        .postData(`${baseUrl}/api/UserAction`, {
        payload: {
            isClientAdmin: !isGlobal,
            isGlobalAdmin: isGlobal,
            users: updatedUsers,
        },
    }, requestInit !== null && requestInit !== void 0 ? requestInit : {})
        .then((r) => {
        if (r.errorCount && r.errorCount !== 0) {
            showError(`${r.error}`);
        }
        else {
            toastSuccess(React.createElement(ToastMessage, { title: NOTIFICATIONS.LABEL.REACTIVATE_USERS, message: NOTIFICATIONS.MESSAGE.REACTIVATE_USERS_SUCCESS }));
        }
        refreshViewSet && setTimeout(refreshViewSet, 1000);
    })
        .catch(showError);
    updateAction(undefined);
});
export const warnNoInActiveUsers = (updateAction, NOTIFICATIONS) => {
    toastWarning(React.createElement(ToastMessage, { title: NOTIFICATIONS.LABEL.REACTIVATE_USERS, message: NOTIFICATIONS.MESSAGE.REACTIVATE_USERS_NO_SELECTION }));
    updateAction(undefined);
};
export const errorNotAuthorized = (updateAction, NOTIFICATIONS) => {
    toastError(React.createElement(ToastMessage, { title: NOTIFICATIONS.LABEL.REACTIVATE_USERS, message: "You are not authorized to perform this action. Please contact your administrator if you believe this is an error." }));
    updateAction(undefined);
};
export default {
    reactivateSelectedUsers,
    warnNoInActiveUsers,
    errorNotAuthorized,
};
