export const overridenViewids = [
  'ChangeEmail',
  'DisableUser',
  'DisableGlobalUser',
  'DisableUsers',
  'DisableGlobalUsers',
  'ReactivateUser',
  'ReactivateGlobalUser',
  'ReactivateUsers',
  'ReactivateGlobalUsers',
  'RequestUserAccess',
  'RejectUsers',
  'ApproveUsers',
  'AddRoles',
  'RemoveRoles',
  'UserPassword',
  'IdentityUserPassword',
  'NonIdentityUserPassword',
  'HelpTopics',
  'UnlockUser',
  'UnlockUsers',
  'UnlockGlobalUser',
  'UnlockGlobalUsers',
];

export default {
  overridenViewids,
};
