export const getResetPasswordUserContent = (multiple = false) => {
    const label = multiple ? `users` : `user`;
    return {
        BUTTONS: {
            CANCEL_RESET: `Cancel`,
            RESET_USERS: `Reset User(s) Password`,
        },
        LABELS: {
            RESET_USERS_TITLE: `Reset User(s) Password `,
            SELECTED_LIST: `Selected user(s)`,
            ACTIVE_LIST: `User(s) whose password can be Reset`,
            ALREADY_DISABLED_LIST: `User(s) whose password cannot be Reset`,
            DISABLE_WARNING_FOR_CLIENT: `You are about to disable the selected user(s) from accessing `,
            DISABLE_USER_PROVIDE_REASON: `Please provide a reason the user(s) are being disabled. `,
            DISABLE_MULTIPLE_USERS_REASONS_MSG: `You can only enter one disable reason for the group of users selected. If the users are being disabled for different reasons, then each should be disabled separately so you can enter different reasons for each user.`,
        },
        NOTIFICATIONS: {
            LABEL: {
                RESETPASSWORD_USERS: `Reset ${label} Password`,
            },
            MESSAGE: {
                RESET_PASSWORD_USERS_SUCCESS: `Selected user(s) password were successfully reset`,
                RESET_PASSWORD_USERS_FAIL: `Something went wrong`,
                RESET_PASSWORD_USERS_NO_SELECTION: `No Independent users selected`,
            },
        },
    };
};
export default {
    resetPasswordUserContent: getResetPasswordUserContent(false),
};
