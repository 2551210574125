import React from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { useUserAuthenticationState } from '@samc/single-spa-authentication';
import { useTenantState } from '@samc/single-spa-tenant-selector';
import { ClientConfiguration, useClientConfiguration } from '@samc/single-spa-client-configuration';
import { RootBase } from './root.component.base';
import type { SingleSpaProps } from './root.component';

/**
 * Validation layer of the root component which fetches the current tenant name and shows an error if no tenant is set
 */
const RootWithTenantCheck = (
  props: SingleSpaProps & {
    gracePeriodActive: boolean;
    authToken: string | undefined;
    clientConfiguration: ClientConfiguration;
    jwt: string | undefined;
  },
): React.ReactElement | null => {
  const { gracePeriodActive } = props;

  const tenantState = useTenantState();
  const tenantName = tenantState?.name;

  if (!tenantName)
    return gracePeriodActive ? null : (
      <MessageBar messageBarType={MessageBarType.error}>
        No client selected - Please select a client and try again
      </MessageBar>
    );

  return <RootBase {...props} tenantName={tenantName} />;
};

/**
 * Validation layer of the root component which fetches user authentication state and shows an error if it is undefined or the user is unauthorized
 */
const RootWithAuthStateCheck = (
  props: SingleSpaProps & { gracePeriodActive: boolean; clientConfiguration: ClientConfiguration },
): React.ReactElement | null => {
  const { gracePeriodActive } = props;
  const authState = useUserAuthenticationState();
  const isUserAuthenticated = React.useMemo(() => !!(authState && authState.isAuthenticated), [authState]);

  if (!isUserAuthenticated)
    return gracePeriodActive ? null : (
      <MessageBar messageBarType={MessageBarType.error}>
        User is unauthorized - Please log out and back in again
      </MessageBar>
    );

  return (
    <RootWithTenantCheck
      {...props}
      authToken={authState?.accessToken?.value}
      jwt={authState?.accessToken?.accessToken}
    />
  );
};

/**
 * Validation layer of the root component which fetches ClientConfiguration and shows an error if it is not defined
 */
const RootWithClientConfigurationCheck = (
  props: SingleSpaProps & { gracePeriodActive: boolean },
): React.ReactElement | null => {
  const { gracePeriodActive } = props;
  const { result: clientConfiguration } = useClientConfiguration();

  if (clientConfiguration === undefined)
    return gracePeriodActive ? null : (
      <MessageBar messageBarType={MessageBarType.error}>Could not load authorization configuration</MessageBar>
    );

  return <RootWithAuthStateCheck {...props} clientConfiguration={clientConfiguration} />;
};

export const RootWithChecks = (props: SingleSpaProps): React.ReactElement | null => {
  const [gracePeriodActive, setGracePeriodActive] = React.useState(true);
  React.useEffect(() => {
    const timer = setTimeout(() => setGracePeriodActive(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return <RootWithClientConfigurationCheck {...props} gracePeriodActive={gracePeriodActive} />;
};

export default RootWithChecks;
