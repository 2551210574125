import React, { useState } from 'react';
import { useFormView, useApplicationContext } from '@samc/screen-config-api';
import useFormData from '@samc/screen-config-core/lib/organisms/FormScreen/useFormData';
import { ToastMessage, toastWarning, toastError } from '@samc/react-ui-core';
import { DisableUsers } from './DisableUsers';
import { FormViews, ViewID, Status } from '../../static/UserType';
const DisableIndividualUser = ({ action, updateAction, refreshViewSet }) => {
    const { viewId, primaryKeyValue } = action;
    const [users, updateUsers] = useState([]);
    const isGlobal = viewId === ViewID.DisableGlobalUser;
    const { data: formViewData } = useFormView(isGlobal ? FormViews.GlobalUserDetail : FormViews.UserDetail);
    const [user, isLoading] = useFormData(formViewData, primaryKeyValue);
    const { currentUser, Entitlements } = useApplicationContext();
    React.useMemo(() => {
        var _a;
        if (!currentUser.hasEntitlement(Entitlements.DisableUsers)) {
            toastError(React.createElement(ToastMessage, { title: "Disable User", message: "You are not authorized to perform this action. Please contact your administrator if you believe this is an error." }));
            updateAction(undefined);
        }
        if (!isLoading && user) {
            if (((_a = user.StatusTypeId) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === Status.Inactive.toLowerCase()) {
                toastWarning(React.createElement(ToastMessage, { title: "Disable User", message: "User is already in Inactive state" }));
                updateAction(undefined);
            }
            else {
                updateUsers([user]);
            }
        }
    }, [currentUser, Entitlements.DisableUsers, isLoading, user, updateAction]);
    return users.length > 0 ? (React.createElement(DisableUsers, { users: users, isGlobal: isGlobal, updateAction: updateAction, refreshViewSet: refreshViewSet })) : (React.createElement(React.Fragment, null));
};
export default DisableIndividualUser;
