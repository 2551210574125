/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import './ProgressBar.scss';
const ProgressBar = ({ steps, currentStep = 1 }) => {
    return (React.createElement("div", { className: "progress-bar" },
        React.createElement("div", { className: "steps-wrapper" }, steps.map((s, i) => (React.createElement(Fragment, { key: s.toString() },
            i > 0 && React.createElement("hr", { className: i <= currentStep ? 'done' : '' }),
            React.createElement("div", { className: "step" },
                React.createElement("button", { type: "button", className: currentStep > i ? 'done' : currentStep === i ? 'current' : '' }, i + 1),
                React.createElement("label", { className: currentStep > i ? 'done' : currentStep === i ? 'current' : '' }, s))))))));
};
export default ProgressBar;
