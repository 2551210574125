import { useFormView } from '@samc/screen-config-api';
import { useFormData } from '@samc/screen-config-core/lib/organisms/FormScreen/useFormData';
import React, { useState } from 'react';
import { FormViews } from '../../static/UserType';
import ChangeEmail from './ChangeEmail';
const ChangeEmailWrapper = ({ action, updateAction, refreshViewSet }) => {
    const { primaryKeyValue } = action;
    const [currentUser, updateUser] = useState();
    const { data: formViewData } = useFormView(FormViews.GlobalUserDetail);
    const [user, isLoading] = useFormData(formViewData, primaryKeyValue);
    React.useMemo(() => {
        if (!isLoading && user) {
            updateUser(user);
        }
    }, [user, isLoading]);
    return ((currentUser && React.createElement(ChangeEmail, { user: currentUser, refreshViewSet: refreshViewSet, updateAction: updateAction })) || (React.createElement(React.Fragment, null)));
};
export default ChangeEmailWrapper;
