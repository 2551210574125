export const getUnlockUserContent = (multiple = false) => {
    const label = multiple ? `users` : `user`;
    return {
        NOTIFICATIONS: {
            LABEL: {
                UNLOCK_USERS: `Unlock ${label}`,
            },
            MESSAGE: {
                UNLOCK_USERS_SUCCESS: `Selected user(s) were successfully unlocked`,
                UNLOCK_USERS_FAIL: `Something went wrong`,
                UNLOCK_USERS_NO_SELECTION: `No locked out users selected`,
            },
        },
    };
};
export default {
    unlockUserContent: getUnlockUserContent(false),
};
