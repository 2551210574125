import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserWizardViewSet from '../../pages/ViewSetPage/UserWizardViewSet';
import MyAccountViewSet from '../../pages/MyAccountViewSetPage/MyAccountViewSet';
import { ViewSetPage } from '../../pages/ViewSetPage/ViewSetPage';

export const Router = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/RequestUserAccess" element={<UserWizardViewSet />} />
      <Route path="/MyAccountDetails/:id/:tabId/:subtabId" element={<MyAccountViewSet />} />
      <Route path="/MyAccountDetails/:id/:tabId" element={<MyAccountViewSet />} />
      <Route path="/MyAccountDetails/:id" element={<MyAccountViewSet />} />
      <Route path="/:id/:tabId/:subtabId" element={<ViewSetPage />} />
      <Route path="/:id/:tabId" element={<ViewSetPage />} />
      <Route path="/:id" element={<ViewSetPage />} />
    </Routes>
  );
};

export default Router;
