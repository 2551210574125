import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Router as CustomRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  LayerWrapper,
  PromptContextProvider,
  ToastMessageContainer,
  toastError,
  WysiwygLicenseProvider,
} from '@samc/react-ui-core';
import { LSThemeWrapper } from '@samc/vmsnext-querybuilder';
import { AuthorizationWrapper, useCurrentUser } from '@samc/single-spa-authentication';
import { StrataProvider } from '@samc/filestorage-react';
import { HeaderContextProvider, HistoryProvider } from '@samc/react-ui-history';
import { ClientConfiguration } from '@samc/single-spa-client-configuration';
import { AgGridLicenseProvider } from '@samc/react-ui-grid';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import { ThemeProvider, defaultTheme } from '@samc/react-ui-theme';

import { ApiProvider } from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';
import { Router } from './templates/Router/Router';
import type { SingleSpaProps } from './root.component';
import { ApplicationContextAdapter } from './contexts/ApplicationContextAdapter';

const queryClient = new QueryClient();
type RootBaseProps = SingleSpaProps & {
  authToken: string | undefined;
  clientConfiguration: ClientConfiguration;
  jwt: string | undefined;
  tenantName: string;
};

interface AuthorizedRootBaseProps extends Pick<SingleSpaProps, 'browserHistory' | 'hashHistory'> {
  headers: Record<string, string>;
  tenantName: string;
}

export const AuthorizedRootBase = (props: AuthorizedRootBaseProps): React.ReactElement<RootBaseProps> => {
  const { hashHistory, browserHistory, headers } = props;

  const { id: currentUserIdGuid } = useCurrentUser();
  const [location, setLocation] = React.useState(hashHistory.location);

  React.useEffect(() => {
    return hashHistory.listen((l) => setLocation(l.location));
  }, [hashHistory]);

  return (
    <QueryClientProvider client={queryClient}>
      <LSThemeWrapper>
        <ThemeProvider style={{ width: '100%', height: '100%' }} value={defaultTheme}>
          <LayerWrapper>
            <HistoryProvider value={{ browserHistory, hashHistory }}>
              <PromptContextProvider>
                <HeaderContextProvider>
                  <EvaluatorConfigurationProvider value={{ currentUserId: currentUserIdGuid.toString() }}>
                    <DndProvider backend={HTML5Backend}>
                      <StrataProvider>
                        <CustomRouter location={location} navigator={hashHistory}>
                          <ApplicationContextAdapter>
                            <ApiProvider
                              value={{
                                requestInit: {
                                  headers,
                                },
                                LiftSiftApi: '/_apis/querybuilder',
                                AggregatorApi: '/_apis/aggregator',
                                TaskApi: '/taskgateway',
                                UserManagementApi: '/_apis/user-management',
                                FolderApi: '/_apis/aggregator/folderapi',
                                ReportingApi: '/_apis/reporting',
                                QueryAuthorizationApi: '/_apis/query-authorization',
                                ShowMessage: (msg: string): React.ReactText => toastError(msg),
                              }}
                            >
                              <Router />
                              <ToastMessageContainer
                                className="single-spa-user-management"
                                position="top-center"
                                draggable
                                pauseOnHover
                                pauseOnFocusLoss
                                autoClose={4000}
                              />
                            </ApiProvider>
                          </ApplicationContextAdapter>
                        </CustomRouter>
                      </StrataProvider>
                    </DndProvider>
                  </EvaluatorConfigurationProvider>
                </HeaderContextProvider>
              </PromptContextProvider>
            </HistoryProvider>
          </LayerWrapper>
        </ThemeProvider>
      </LSThemeWrapper>
    </QueryClientProvider>
  );
};

/**
 * Inner Root layer which takes validated parameters from the Check layers and injects them into the component hierarchy
 */
export const RootBase = (props: RootBaseProps): React.ReactElement | null => {
  const { authToken, clientConfiguration, jwt, tenantName } = props;

  const headers = {
    Authorization: `Bearer ${authToken}`,
    'X-Tenant': tenantName,
  };

  return (
    <AgGridLicenseProvider license={clientConfiguration.agGridLicense}>
      <WysiwygLicenseProvider value={clientConfiguration.tinyMceLicense}>
        <AuthorizationWrapper
          config={{
            ...clientConfiguration,
            defaultHeaders: Object.entries(headers).map(([k, v]) => ({ name: k, value: v })),
          }}
          jwt={jwt}
        >
          <AuthorizedRootBase {...props} headers={headers} />
        </AuthorizationWrapper>
      </WysiwygLicenseProvider>
    </AgGridLicenseProvider>
  );
};

export default RootBase;
