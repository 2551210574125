var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useQBQuery } from '@samc/screen-config-api';
import { getDisableUserContent } from '../../static/DisableUserContent';
import { DisableUsers } from './DisableUsers';
import { Status, ViewID } from '../../static/UserType';
import { globalUserAdhocListViewMembers, userAdhocListViewMembers, warnNoActiveUsers } from './DisableUsers.utils';
const DisableUserModalWrapper = ({ action, users, updateAction, refreshViewSet }) => {
    const { viewId } = action;
    const { NOTIFICATIONS } = React.useMemo(() => getDisableUserContent(), [users]);
    const isGlobal = viewId === ViewID.DisableGlobalUsers;
    const [userData, setUserData] = React.useState([]);
    const qbQuery = useQBQuery();
    const ids = React.useMemo(() => users.map((x) => `'${x.UserId}'`).join(','), [users]);
    React.useEffect(() => {
        function fetchQbData() {
            return __awaiter(this, void 0, void 0, function* () {
                const loadViewSetData = () => __awaiter(this, void 0, void 0, function* () {
                    return qbQuery({
                        domainId: isGlobal ? 'GlobalUser' : 'User',
                        primaryViewId: '-1',
                        filterIds: [],
                        paging: { start: 0, stop: 1000 },
                        sorting: {},
                        adhocListViewMembers: isGlobal ? globalUserAdhocListViewMembers : userAdhocListViewMembers,
                        summaryMode: false,
                        adhocFilter: {
                            filterName: 'adhoc',
                            advancedInd: true,
                            expressionLang: 'Centric',
                            advancedExpression: `([UserId] IN (${ids}))`,
                        },
                    });
                });
                if (users.length > 0) {
                    const result = yield loadViewSetData();
                    if (result.Data &&
                        result.Data.length &&
                        result.Data.some(({ StatusTypeId }) => { var _a; return ((_a = String(StatusTypeId)) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === Status.Active.toLowerCase(); }))
                        setUserData([...result.Data]);
                    else
                        warnNoActiveUsers(updateAction, NOTIFICATIONS);
                }
                else
                    warnNoActiveUsers(updateAction, NOTIFICATIONS);
            });
        }
        fetchQbData();
    }, [NOTIFICATIONS, qbQuery, updateAction]);
    return userData.length ? (React.createElement(DisableUsers, { users: userData, isGlobal: isGlobal, updateAction: updateAction, refreshViewSet: refreshViewSet })) : (React.createElement(React.Fragment, null));
};
export default DisableUserModalWrapper;
