var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '@fluentui/react';
import { GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { useQBQuery, useApplicationContext } from '@samc/screen-config-api';
import { Button, toastError, ToastMessage, toastSuccess, toastWarning, UnsavedChangesDialog, ControlBar, } from '@samc/react-ui-core';
import { useHistoryContext } from '@samc/react-ui-history';
import SitusHeader from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import { apiProvider, useApiContext } from '@samc/user-management-api';
import { adhocListViewMembers } from './UpdateRoles.utils';
import { ViewID } from '../../static/UserType';
import './UpdateRoles.scss';
const UpdateRoles = (props) => {
    const { users, updateAction, refreshViewSet, action } = props;
    const [isOpen, toggleModal] = useState(true);
    const [showUnSave, setShowUnSave] = React.useState(false);
    const [data, updateData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [wizardIsDirty, setWizardisDirty] = useState(false);
    const [transition, setTransition] = useState();
    const { browserHistory, hashHistory } = useHistoryContext();
    useEffect(() => {
        if (!wizardIsDirty)
            return () => undefined;
        const unblock = browserHistory.block((tx) => {
            setShowUnSave(true);
            setTransition(Object.assign({}, tx));
        });
        return () => {
            unblock();
        };
    }, [browserHistory, hashHistory, wizardIsDirty]);
    const closeUnSaveModal = useCallback((discard) => {
        if (discard && transition) {
            setWizardisDirty(false);
            const { location, retry } = transition;
            retry();
            if (transition.action === 'PUSH') {
                setTimeout(() => browserHistory.push(location));
            }
        }
        setShowUnSave(false);
        if (discard) {
            updateAction(undefined);
        }
    }, [browserHistory, transition]);
    const closeModal = () => {
        if (data.length > 0)
            setShowUnSave(true);
        else {
            toggleModal(false);
            updateAction(undefined);
        }
    };
    const cancel = () => {
        toggleModal(false);
        updateAction(undefined);
    };
    const qbQuery = useQBQuery();
    const ids = React.useMemo(() => users.map((x) => `'${x.UserId}'`).join(','), [users]);
    const { currentUser, Entitlements } = useApplicationContext();
    // to get data of selected contacts from companycatlogue
    React.useEffect(() => {
        function fetchQbData() {
            return __awaiter(this, void 0, void 0, function* () {
                const loadViewSetData = () => __awaiter(this, void 0, void 0, function* () {
                    return qbQuery({
                        domainId: 'User',
                        primaryViewId: '-1',
                        adhocListViewMembers,
                        filterIds: [],
                        paging: { start: 0, stop: 1000 },
                        sorting: {},
                        summaryMode: false,
                        adhocFilter: {
                            filterName: 'adhoc',
                            advancedInd: true,
                            expressionLang: 'Centric',
                            advancedExpression: `([UserId] IN (${ids}))`,
                        },
                    });
                });
                if (!currentUser.hasEntitlement(Entitlements.AssignRoles)) {
                    toastError(React.createElement(ToastMessage, { title: action.viewId === ViewID.AddRoles ? 'Add Roles' : 'Remove Roles', message: "You are not authorized to perform this action. Please contact your administrator if you believe this is an error." }));
                    updateAction(undefined);
                }
                if (users.length > 0) {
                    const result = yield loadViewSetData();
                    setUserData([...result.Data]);
                }
                else {
                    toastWarning(React.createElement(ToastMessage, { title: action.viewId === ViewID.AddRoles ? 'Add Roles' : 'Remove Roles', message: "No user selected" }));
                    updateAction(undefined);
                }
            });
        }
        fetchQbData();
        if (action.viewId === ViewID.AddRoles || action.viewId === ViewID.RemoveRoles) {
            setWizardisDirty(true);
        }
        // qbQuery not included in this since it is a function
    }, []);
    const roles = React.useMemo(() => data.map((r) => ({
        RoleId: String(r.Id),
        IsRoleAssign: action.viewId === ViewID.AddRoles,
    })), [data, action]);
    const api = useApiContext();
    const userRoles = React.useMemo(() => userData
        .map((u) => ({
        StatusTypeId: u.StatusTypeId,
        EmailAddress: u.EmailAddress,
        LastName: u.LastName,
        FirstName: u.FirstName,
        CompanyName: u.CompanyName,
        CreatedOn: u.CreatedOn,
        CreatedBy: u.CreatedBy,
        LastLogin: u.LastLogin,
        UpdatedOn: u.UpdatedOn,
        UpdatedBy: u.UpdatedBy,
        DisabledOn: u.DisabledOn,
        InActivationReason: u.InActivationReason,
        UserRole: [],
        UserId: u.UserId,
    }))
        .map((u) => {
        return Object.assign(Object.assign({}, u), { UserRole: roles });
    }), [roles, userData]);
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        yield apiProvider
            .putData(`${window.location.origin}/_apis/user-management/api/UserAction/AddRemoveRoles`, {
            payload: {
                isClientAdmin: true,
                isGlobalAdmin: false,
                users: userRoles,
            },
        }, (_a = api.requestInit) !== null && _a !== void 0 ? _a : {})
            .then((r) => {
            if (r.errorCount && r.errorCount !== 0) {
                toastError(React.createElement(ToastMessage, { title: "Error", message: "Something went wrong" }));
            }
            else if (action.viewId === ViewID.AddRoles) {
                toastSuccess(React.createElement(ToastMessage, { title: "Successful", message: "Roles Added" }));
            }
            else {
                toastSuccess(React.createElement(ToastMessage, { title: "Successful", message: "Roles Removed" }));
            }
            setTimeout(refreshViewSet, 1000);
        })
            .catch((error) => {
            toastError(React.createElement(ToastMessage, { title: "Error", message: error }));
        });
        updateAction(undefined);
    });
    const getEmailList = (userList) => {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "modal-text" },
                "Selected Users (",
                userList.length,
                ")"),
            React.createElement("ul", { className: "user-list" }, userList.map(({ EmailAddress }) => (React.createElement("li", { key: String(EmailAddress) }, String(EmailAddress)))))));
    };
    return userData.length === 0 ? (React.createElement(React.Fragment, null)) : (React.createElement(Modal, { isOpen: isOpen, onDismiss: closeModal, isBlocking: false, className: "userModals" },
        React.createElement("div", { className: "view-modal", style: {
                height: '85vh',
                width: '85vw',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#f8f8f8',
            } },
            React.createElement(SitusHeader, { title: action.viewId === ViewID.AddRoles ? 'Bulk Add Roles' : 'Bulk Remove Roles' },
                React.createElement(ControlBar, { cancel: cancel, cancelDisabled: false, additionalControls: React.createElement(Button, { text: action.viewId === ViewID.AddRoles ? 'Add Roles' : 'Remove Roles', disabled: data.length === 0, onClick: handleSubmit }) })),
            React.createElement("div", { className: "role-modal-content" }, getEmailList(userRoles)),
            action.viewId === ViewID.AddRoles && (React.createElement("div", { className: "roleText" },
                React.createElement("p", null, "Select the roles you would like to add to the selected users."),
                React.createElement("p", null, "If they do not have the role, it will be added to their profiles."),
                React.createElement("p", null, "If they already have the role, nothing will change."))),
            action.viewId === ViewID.RemoveRoles && (React.createElement("div", { className: "roleText" },
                React.createElement("p", null, "Select the roles you would like to remove from the selected users."),
                React.createElement("p", null, "If they don`t have the role, nothing will happen."),
                React.createElement("p", null, "If they have the role, it will be removed to their profiles. They will lose all the access granted via that role."))),
            React.createElement("div", { className: "updateRolesGrid" },
                React.createElement(GridScreenWrapper, { listViewId: "ROEN_AllRoles", filters: [`([TypeId] = 'PORTFOLIOACCESS' || [TypeId] = 'SYSTEMADMIN')`], onSelectionChanges: updateData, ServiceDrivenViewSet: ServiceDrivenViewSet }))),
        React.createElement(UnsavedChangesDialog, { show: showUnSave, close: closeUnSaveModal })));
};
export default UpdateRoles;
