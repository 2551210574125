var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Checkbox, Modal, TextField, TooltipHost } from '@fluentui/react';
import SitusHeader from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import { TextInput } from '@samc/react-ui-form';
import { TaskStatus, useApplicationContext, } from '@samc/screen-config-api';
import React, { useState } from 'react';
import { Button, ToastMessage, toastSuccess, toastError, ControlBar } from '@samc/react-ui-core';
import { TaskResponsePopup, } from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { apiProvider, useApiContext } from '@samc/user-management-api';
import './ChangeEmail.scss';
import { content } from '../../static/EmailChangeContent';
const { BTN_LABEL, LABELS, MESSAGES, FORMS, REASONS } = content;
const formName = FORMS.FORM_NAME;
const formData = {
    formId: FORMS.FORM_ID,
    formName: FORMS.FORM_NAME,
};
const lastTaskRequests = [
    {
        domainId: formName,
        payload: {
            [FORMS.FORM_ID]: formData,
        },
        viewId: '-1',
        requestIdentifier: formName,
    },
];
const domainNameMap = {
    [formName]: 'formName',
};
const ChangeEmail = ({ user, refreshViewSet, updateAction }) => {
    const [showModal, toggleModal] = useState(true);
    const [newEmailAddress, updateNewEmailAddress] = useState('');
    const [confirmEmailAddress, updateConfirmEmailAddress] = useState('');
    const [changeReason, updateReason] = useState('');
    const [companyNotChanged, toggleChangeConfirmed] = useState(false);
    const [lastTaskResponses, setLastTaskResponses] = React.useState([]);
    const api = useApiContext();
    const closeModal = () => {
        toggleModal(false);
        updateAction(undefined);
    };
    const [errorPopupIsVisible, setErrorPopupIsVisible] = React.useState(false);
    const { currentUser, Entitlements } = useApplicationContext();
    if (!currentUser.hasEntitlement(Entitlements.UpdateUsers)) {
        toastError(React.createElement(ToastMessage, { title: "Change Email", message: "You are not authorized to perform this action. Please contact your administrator if you believe this is an error." }));
        updateAction(undefined);
    }
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const updatedUser = Object.assign(Object.assign({}, user), { NewEmailAddress: newEmailAddress, ConfirmNewEmailAddress: confirmEmailAddress, EmailAddressChangeReason: changeReason, companyNotChanged });
        setLastTaskResponses([]);
        delete updatedUser.UserID;
        delete updatedUser.primaryKey;
        delete updatedUser.OrderBy;
        delete updatedUser.StatusTypeId;
        delete updatedUser.InActivationReason;
        yield apiProvider
            .putData(`${window.location.origin}/_apis/user-management/api/UserAction/ChangeEmail`, {
            payload: {
                isClientAdmin: false,
                isGlobalAdmin: true,
                users: [updatedUser],
            },
        }, (_a = api.requestInit) !== null && _a !== void 0 ? _a : {})
            .then((r) => {
            if (r != null && r.status !== 'DONE') {
                const errors = r;
                const taskMessages = errors.map((u) => ({
                    type: 0,
                    code: FORMS.INVALID,
                    title: String(u.Message),
                    description: String(u.Message),
                    recordIdentifier: FORMS.FORM_ID,
                    fields: [],
                    overridable: false,
                }));
                setLastTaskResponses([
                    {
                        requestIdentifier: formName,
                        domainId: formName,
                        messages: taskMessages,
                        messageCount: taskMessages.length,
                        prompts: [],
                        childResponses: {},
                        statusCode: TaskStatus.Failed,
                        newIds: {},
                        updatedIds: [],
                        deletedIds: [],
                    },
                ]);
                setErrorPopupIsVisible(true);
            }
            else {
                toastSuccess(React.createElement(ToastMessage, { title: MESSAGES.CHANGE_EMAIL_LABEL, message: MESSAGES.EMAIL_CHANGE_SUCCESS }));
                updateAction(undefined); // This ensures the action state is cleared from viewsetpage so the modal doesn't re-appears
                if (refreshViewSet)
                    setTimeout(refreshViewSet, 1000);
            }
        })
            .catch((_e) => {
            const taskMessages = [
                {
                    type: 0,
                    code: FORMS.ERROR,
                    title: String(MESSAGES.GENERIC_ERROR),
                    description: String(MESSAGES.GENERIC_ERROR),
                    recordIdentifier: FORMS.FORM_ID,
                    fields: [],
                    overridable: false,
                },
            ];
            setLastTaskResponses([
                {
                    requestIdentifier: formName,
                    domainId: formName,
                    messages: taskMessages,
                    messageCount: taskMessages.length,
                    prompts: [],
                    childResponses: {},
                    statusCode: TaskStatus.Failed,
                    newIds: {},
                    updatedIds: [],
                    deletedIds: [],
                },
            ]);
            setErrorPopupIsVisible(true);
            updateAction(undefined); // This ensures the action state is cleared from viewsetpage so the modal doesn't re-appears
        });
    });
    const doNothing = () => undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: showModal, onDismiss: closeModal, isBlocking: false, containerClassName: "email-change-popup", className: "userModals" },
            React.createElement("div", { className: "email-change-modal", style: { display: 'block', width: '33vw' } },
                React.createElement(SitusHeader, { title: LABELS.TITLE },
                    React.createElement(ControlBar, { cancel: closeModal, cancelDisabled: false, additionalControls: React.createElement(TooltipHost, { content: newEmailAddress.trim() === '' ||
                                confirmEmailAddress.trim() === '' ||
                                changeReason.trim() === '' ||
                                !companyNotChanged
                                ? MESSAGES.TOOLTIP_SAVE_ERROR
                                : '' },
                            React.createElement(Button, { text: BTN_LABEL.SAVE, disabled: newEmailAddress.trim() === '' ||
                                    confirmEmailAddress.trim() === '' ||
                                    changeReason.trim() === '' ||
                                    !companyNotChanged, onClick: onSubmit })) })),
                React.createElement("div", { className: "email-change-content" },
                    React.createElement("section", null,
                        React.createElement(TextInput, { label: LABELS.CURRENT_EMAIL, value: user.EmailAddress, readonly: true, onChange: doNothing })),
                    React.createElement("section", null,
                        React.createElement(TextInput, { label: LABELS.NEW_EMAIL, value: newEmailAddress, onChange: updateNewEmailAddress, hasError: newEmailAddress.trim() === '' })),
                    React.createElement("section", null,
                        React.createElement(TextInput, { label: LABELS.CONFIRM_EMAIL, value: confirmEmailAddress, onChange: updateConfirmEmailAddress, hasError: confirmEmailAddress.trim() === '' })),
                    React.createElement("div", { className: "modal-buttons" },
                        React.createElement("label", { htmlFor: "change-reason" }, LABELS.CHANGE_REASON),
                        REASONS.map((r) => (React.createElement(Button, { key: r[0], text: r[0], onClick: () => updateReason(r[1]) })))),
                    React.createElement(TextField, { multiline: true, rows: 2, resizable: false, value: changeReason, className: changeReason.trim() === '' ? 'custom-required' : '', onChange: (_e, s = '') => updateReason(s) }),
                    React.createElement("section", { className: "confirm-change" },
                        React.createElement(Checkbox, { label: LABELS.CONFIRM_CHANGE, checked: companyNotChanged, required: true, className: companyNotChanged ? 'checkbox-custom' : '', onChange: (_e, checked) => toggleChangeConfirmed(checked === true) })))),
            React.createElement("div", null)),
        React.createElement(TaskResponsePopup, { responses: lastTaskResponses, requests: lastTaskRequests, domainNameMap: domainNameMap, visible: errorPopupIsVisible, setVisible: setErrorPopupIsVisible })));
};
export default ChangeEmail;
