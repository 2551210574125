var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Modal } from '@fluentui/react';
import { SitusHeader } from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import { useFormView, useApplicationContext } from '@samc/screen-config-api';
import useFormData from '@samc/screen-config-core/lib/organisms/FormScreen/useFormData';
import { Button, ToastMessage, toastError } from '@samc/react-ui-core';
import { useApiContext } from '@samc/user-management-api';
import { FormViews, ViewID } from '../../static/UserType';
import { resetPasswordSelectedUsers } from './ResetPasswordUsers.utils';
const ResetPasswordIndividualUser = ({ action, updateAction, refreshViewSet, userId }) => {
    const { viewId, primaryKeyValue } = action;
    const [showModal, toggleModal] = React.useState(true);
    const isGlobal = viewId === ViewID.IdentityUserPassword || ViewID.NonIdentityUserPassword;
    const { data: formViewData } = useFormView(isGlobal ? FormViews.GlobalUserDetail : FormViews.UserDetail);
    const [user, isLoading] = useFormData(formViewData, primaryKeyValue || userId);
    const closeModal = () => {
        toggleModal(false);
        updateAction(undefined);
    };
    const api = useApiContext();
    const { currentUser, Entitlements } = useApplicationContext();
    if (!currentUser.hasEntitlement(Entitlements.UpdateUsers)) {
        toastError(React.createElement(ToastMessage, { title: "Reset Password", message: "You are not authorized to perform this action. Please contact your administrator if you believe this is an error." }));
        updateAction(undefined);
    }
    const onRequest = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!isLoading && user) {
            resetPasswordSelectedUsers([user], api.requestInit, updateAction, refreshViewSet);
        }
    });
    return (React.createElement(Modal, { isOpen: showModal, onDismiss: closeModal, isBlocking: false, className: "userModals" },
        React.createElement("div", { className: "ag-theme-balham ag-theme-centricity disable-user-modal", style: { display: 'block', width: '66vw' } },
            React.createElement(SitusHeader, { title: "Reset User\u2019s Password" }),
            React.createElement("div", { className: "disable-modal-content" },
                React.createElement("div", { className: "modal-text" }, viewId === ViewID.IdentityUserPassword
                    ? 'Please click the below button to reset the user’s password. The user will receive an automated email via Okta to reset their Password.'
                    : 'VMS Admins cannot reset the user account because it is linked the user’s firmwide password. Advise the user to contact their internal adminstrator.'),
                React.createElement("div", { className: "modal-buttons" }, viewId === ViewID.IdentityUserPassword ? (React.createElement(React.Fragment, null,
                    React.createElement(Button, { key: "Cancel", text: "Cancel", disabled: false, onClick: closeModal }),
                    React.createElement(Button, { key: "Reset", text: "Reset", disabled: false, onClick: onRequest }))) : (React.createElement(Button, { key: "OK", text: "OK", disabled: false, onClick: closeModal }))),
                React.createElement("div", null)))));
};
export default ResetPasswordIndividualUser;
