import React from 'react';
import { useFormView, useApplicationContext } from '@samc/screen-config-api';
import useFormData from '@samc/screen-config-core/lib/organisms/FormScreen/useFormData';
import { ToastMessage, toastWarning, toastError } from '@samc/react-ui-core';
import { useApiContext } from '@samc/user-management-api';
import { FormViews, ViewID, Status } from '../../static/UserType';
import { reactivateSelectedUsers } from './ReactivateUsers.utils';
const ReactivateIndividualUser = ({ action, updateAction, refreshViewSet }) => {
    const { viewId, primaryKeyValue } = action;
    const isGlobal = viewId === ViewID.ReactivateGlobalUser;
    const { data: formViewData } = useFormView(isGlobal ? FormViews.GlobalUserDetail : FormViews.UserDetail);
    const [user, isLoading] = useFormData(formViewData, primaryKeyValue);
    const api = useApiContext();
    const { currentUser, Entitlements } = useApplicationContext();
    React.useEffect(() => {
        var _a;
        if (!isLoading && user) {
            if (!currentUser.hasEntitlement(Entitlements.DisableUsers)) {
                toastError(React.createElement(ToastMessage, { title: "Reactivate User", message: "You are not authorized to perform this action. Please contact your administrator if you believe this is an error." }));
                updateAction(undefined);
            }
            else if (((_a = user.StatusTypeId) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === Status.Active.toLowerCase()) {
                toastWarning(React.createElement(ToastMessage, { title: "Reactivate User", message: "User is already in active state" }));
                updateAction(undefined);
            }
            else {
                reactivateSelectedUsers([user], isGlobal, api.requestInit, updateAction, refreshViewSet);
            }
        }
    }, [
        isLoading,
        user,
        currentUser,
        Entitlements.DisableUsers,
        updateAction,
        isGlobal,
        api.requestInit,
        refreshViewSet,
    ]);
    return React.createElement(React.Fragment, null);
};
export default ReactivateIndividualUser;
