import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MessageBar, MessageBarType, UnsavedChangesDialog } from '@samc/react-ui-core';
import { useHistoryContext } from '@samc/react-ui-history';
import { Transition } from 'history';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import { Entitlements, useCurrentUser } from '@samc/single-spa-authentication';
import { ProgressIndicator } from '@fluentui/react';
import { UserWizard } from '@samc/user-management-core/lib/organisms/UserWizard/UserWizard';

const UserWizardViewSet = (): React.ReactElement => {
  const [contactIds] = useSearchParams();
  const ids: string | null = contactIds.get('contactIds');

  const user = useCurrentUser();
  const validateUser = React.useMemo(() => user.hasEntitlement(Entitlements.User.RequestUsers), [user]);

  const [showModal, toggleModal] = useState(false);
  const [wizardIsDirty, setWizardisDirty] = useState(false);
  const [transition, setTransition] = useState<Transition>();

  const { browserHistory, hashHistory } = useHistoryContext();

  useEffect(() => {
    if (!wizardIsDirty) return (): void => undefined;

    const unblock = browserHistory.block((tx: Transition) => {
      toggleModal(true);
      setTransition({ ...tx });
    });

    return (): void => {
      unblock();
    };
  }, [browserHistory, hashHistory, wizardIsDirty]);

  const closeModal = useCallback(
    (discard: boolean) => {
      if (discard && transition) {
        setWizardisDirty(false);
        const { action, location, retry } = transition;
        retry();
        if (action === 'PUSH') {
          setTimeout(() => browserHistory.push(location));
        }
      }

      toggleModal(false);
    },
    [browserHistory, transition],
  );

  if (!user.isLoaded) return <ProgressIndicator label="Loading User..." />;

  if (!validateUser)
    return (
      <div>
        <MessageBar
          messageBarType={MessageBarType.error}
          title="Error loading the Request User Access"
          text="You are not authorized to view this page. Please contact your administrator if you believe this is an error."
        />
      </div>
    );

  return (
    <EvaluatorConfigurationProvider value={{}}>
      {ids && ids !== 'null' ? (
        <UserWizard contactIds={ids} setWizardisDirty={setWizardisDirty} />
      ) : (
        <UserWizard setWizardisDirty={setWizardisDirty} />
      )}
      <UnsavedChangesDialog show={showModal} close={closeModal} />
    </EvaluatorConfigurationProvider>
  );
};

export default UserWizardViewSet;
