import React from 'react';
import { ApplicationContextProvider as ScreenConfigApplicationContextProvider, } from '@samc/screen-config-api';
const _currentUser = {
    id: undefined,
    hasEntitlement: () => {
        throw new Error('Using the default implementation of ApplicationContext.currentUser.hasEntitlement - this means the context is being consumed outside of a valid provider, which is likely a bug.');
    },
};
const _systemInternalIngressPort = 8443;
const _systemExecuteMaxRecordsToReturn = 2000000;
export const ApplicationContext = React.createContext({
    CanUserReassignTask: false,
    CanUserResendNotification: false,
    CurrentUser: _currentUser,
    Entitlements: {},
    SystemExecuteMaxRecordsToReturn: _systemExecuteMaxRecordsToReturn,
    SystemInternalIngressPort: _systemInternalIngressPort,
});
export const ApplicationContextProvider = ({ CanUserReassignTask = false, CanUserResendNotification = false, children, CurrentUser = _currentUser, Entitlements = {}, SystemInternalIngressPort = _systemInternalIngressPort, SystemExecuteMaxRecordsToReturn = _systemExecuteMaxRecordsToReturn, }) => {
    const value = React.useMemo(() => ({
        CanUserReassignTask,
        CanUserResendNotification,
        CurrentUser,
        Entitlements,
        SystemInternalIngressPort,
        SystemExecuteMaxRecordsToReturn,
    }), [
        CanUserReassignTask,
        CanUserResendNotification,
        CurrentUser,
        Entitlements,
        SystemExecuteMaxRecordsToReturn,
        SystemInternalIngressPort,
    ]);
    return (React.createElement(ScreenConfigApplicationContextProvider, { currentUser: CurrentUser, Entitlements: Entitlements },
        React.createElement(ApplicationContext.Provider, { value: value }, children)));
};
export default ApplicationContextProvider;
