import React from 'react';
import { useFormView } from '@samc/screen-config-api';
import useFormData from '@samc/screen-config-core/lib/organisms/FormScreen/useFormData';
import { ToastMessage, toastWarning } from '@samc/react-ui-core';
import { useApiContext } from '@samc/user-management-api';
import { FormViews, ViewID, Status } from '../../static/UserType';
import { unlockSelectedUsers } from './UnlockUsers.utils';
const UnlockIndividualUser = ({ action, updateAction, refreshViewSet }) => {
    const { viewId, primaryKeyValue } = action;
    const isGlobal = viewId === ViewID.UnlockGlobalUser;
    const { data: formViewData } = useFormView(isGlobal ? FormViews.GlobalUserDetail : FormViews.UserDetail);
    const [user, isLoading] = useFormData(formViewData, primaryKeyValue);
    const api = useApiContext();
    React.useMemo(() => {
        if (!isLoading && user) {
            if (user.OktaStatus === Status.OktaActive ||
                user.OktaStatus === Status.OktaSuspended ||
                user.OktaStatus === Status.OktaProvisioned ||
                user.OktaStatus === Status.OktaDeProvisioned) {
                toastWarning(React.createElement(ToastMessage, { title: "Unlock User", message: "User is already in unlocked state" }));
                updateAction(undefined);
            }
            else if (user.OktaStatus === Status.OktaLockedOut) {
                unlockSelectedUsers([user], isGlobal, api.requestInit, updateAction, refreshViewSet);
            }
        }
    }, [user, isLoading, isGlobal, api.requestInit, refreshViewSet, updateAction]);
    return React.createElement(React.Fragment, null);
};
export default UnlockIndividualUser;
