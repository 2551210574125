var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { SearchBox } from '@fluentui/react';
import { Grid } from '@samc/react-ui-grid';
import { useDebouncedCallback } from '@samc/react-ui-core';
import { useQBQuery } from '@samc/screen-config-api';
import { Status } from '../../static/UserType';
const noop = () => undefined;
export const GridSearch = ({ listViewId, rightGridRows, setRightGridRows }) => {
    const [leftGridRows, setLeftGridRows] = React.useState([]);
    const qbQuery = useQBQuery();
    const onSelectionChanged = (e) => {
        const selectedLeftRowsGrid = e.api.getSelectedRows();
        const rows = selectedLeftRowsGrid.filter((row) => !rightGridRows.some((r) => r.Id === row.Id));
        const leftdata = leftGridRows.filter((row) => !rows.some((r) => r.Id === row.Id));
        setRightGridRows([...rows, ...rightGridRows]);
        setLeftGridRows(leftdata);
    };
    const columns = [
        { field: 'FirstName', headerName: 'First Name' },
        { field: 'LastName', displayNameRule: 'Last Name' },
        { field: 'CompanyName', displayNameRule: 'Company' },
        { field: 'EmailAddress', displayNameRule: 'Email Address' },
        { field: 'Id', displayNameRule: 'ContactId', visibleRule: 'false' },
    ];
    // to remove selected ids from search
    const ids = React.useMemo(() => rightGridRows.map((x) => ` AND ([Id] NOT CONTAINS '${x.Id}')`).join(''), [rightGridRows]);
    const adhocListViewMembers = React.useMemo(() => [
        {
            scalarExpression: `[FirstName]`,
            scalarDisplayName: 'FirstName',
        },
        {
            scalarExpression: '[LastName]',
            scalarDisplayName: 'LastName',
        },
        {
            scalarExpression: `[Id]`,
            scalarDisplayName: 'Id',
        },
        {
            scalarExpression: '[EmailAddress]',
            scalarDisplayName: 'EmailAddress',
        },
        {
            scalarExpression: `[CompanyName]`,
            scalarDisplayName: 'CompanyName',
        },
        {
            scalarExpression: `[UserCreationDate]`,
            scalarDisplayName: 'UserCreationDate',
        },
        {
            scalarExpression: `[UserStatus]`,
            scalarDisplayName: 'UserStatus',
        },
    ], []);
    // to get data of selected contacts from company catalog
    const adhocfilterData = (expression) => {
        function fetchQbData() {
            return __awaiter(this, void 0, void 0, function* () {
                const loadViewSetData = () => __awaiter(this, void 0, void 0, function* () {
                    return qbQuery({
                        domainId: listViewId,
                        primaryViewId: '-1',
                        filterIds: [],
                        paging: { start: 0, stop: 1000 },
                        adhocListViewMembers,
                        sorting: {},
                        summaryMode: false,
                        adhocFilter: {
                            filterName: 'adhoc',
                            advancedInd: true,
                            expressionLang: 'Centric',
                            advancedExpression: expression,
                        },
                    });
                });
                const result = yield loadViewSetData();
                setLeftGridRows([...result.Data]);
            });
        }
        fetchQbData();
        // qbQuery not included in this since it is a function
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    const handleSearch = useDebouncedCallback((_event, v) => {
        if (ids.length === 0) {
            const data = `(([FirstName] CONTAINS '${v}') OR ([LastName] CONTAINS '${v}') OR ([EmailAddress] CONTAINS '${v}') OR ([CompanyName] CONTAINS '${v}'))`;
            adhocfilterData(data);
        }
        else {
            const dataIds = `((([FirstName] CONTAINS '${v}') OR ([LastName] CONTAINS '${v}') OR ([EmailAddress] CONTAINS '${v}') OR ([CompanyName] CONTAINS '${v}')) ${ids})`;
            adhocfilterData(dataIds);
        }
    }, [], 300);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "search" },
            React.createElement("label", { className: "searchLabel" }, "Search"),
            React.createElement(SearchBox, { onChange: handleSearch, className: "customSearchBox", showIcon: true })),
        React.createElement("label", { className: "info" }, "*Contacts with a First Name, Last Name or Company Names that meet the search string are listed below."),
        React.createElement("br", null),
        React.createElement("label", { className: "info" }, "*Contacts who are active user(s) or pending user(s) cannot be selected."),
        React.createElement("div", { className: "gridscreen" },
            React.createElement(Grid, { onSubmit: noop, isEditingDefault: true, suppressEditToggle: true, suppressAddRowButton: true, suppressDeleteButton: true, suppressClearFilter: true, suppressExcelExport: true, suppressRowSpacing: true, suppressDeleteConfirmationModal: true, suppressColumnFilters: true, suppressFullScreen: true, stopEditingWhenCellsLoseFocus: true, data: leftGridRows, fields: columns, onSelectionChanged: onSelectionChanged, gridOptions: {
                    isRowSelectable: (node) => {
                        var _a, _b;
                        return node.data.UserCreationDate === null &&
                            ((_a = node.data.UserStatus) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== Status.Active.toLowerCase() &&
                            ((_b = node.data.UserStatus) === null || _b === void 0 ? void 0 : _b.toLowerCase()) !== Status.Pending.toLowerCase();
                    },
                } }))));
};
export default GridSearch;
