var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useNavigate } from 'react-router';
import GridPicklistManager from '@samc/picklist-core/lib/utilities/GridPicklistManager/GridPicklistManager';
import { useEvaluator } from '@samc/expressions-react';
import { ServiceDrivenViewSet } from '@samc/screen-config-core';
import { toastError, ToastMessage, toastSuccess } from '@samc/react-ui-core';
import { Grid } from '@samc/react-ui-grid';
import GridScreenWrapper from '@samc/screen-config-core/lib/organisms/GridScreenWrapper/GridScreenWrapper';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { transformPicklistGridFieldConfigurations } from '@samc/picklist-core/lib/hooks/usePicklistGridFields/usePicklistGridFields';
import { PicklistDisplaySetting, PicklistSetting, PicklistSortType, usePicklistGetter, usePicklistItemGetter, } from '@samc/picklist-api';
import { Spinner } from '@fluentui/react';
import { apiProvider, useApiContext } from '@samc/user-management-api';
import { TwoGrids } from '../UserTwoGrid/TwoGrids';
import { NavigationPanel } from './NavigationPanel';
import ProgressBar from './ProgressBar';
import './UserWizard.scss';
import { baseUrl } from '../DisableUsers/DisableUsers.utils';
const picklistField = {
    id: uuid(),
    picklistId: 'ROEN_Role_Picklist',
    displaySetting: PicklistDisplaySetting.LongName,
    sortType: PicklistSortType.SortOrder,
    setting: PicklistSetting.MultiSelect,
    optionalDisplaySetting: PicklistDisplaySetting.CustomShortName,
    gridFieldConfiguration: [],
    valueSetting: PicklistDisplaySetting.Id,
};
export const UserWizard = (props) => {
    const { contactIds, setWizardisDirty } = props;
    const [currentStep, setCurrentStep] = React.useState(0);
    const steps = ['Select Contact(s)', 'Select Roles', 'Confirm and Submit'];
    const [selectedRowsRoles, setSelectedRowsRoles] = React.useState([]);
    const [selectedRolesUser, setSelectedRolesUser] = React.useState([]);
    const [selectedRowsUser, setSelectedRowsUser] = React.useState([]);
    const [isSubmit, setIsSubmit] = React.useState(false);
    const navigate = useNavigate();
    const { evaluate } = useEvaluator();
    const picklistGetter = usePicklistGetter();
    const picklistItemGetter = usePicklistItemGetter();
    const api = useApiContext();
    const roles = React.useMemo(() => selectedRowsRoles
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .sort((a, b) => (a.Name > b.Name ? 1 : -1))
        .map((e) => e.Id)
        .join(','), [selectedRowsRoles]);
    const users = React.useMemo(() => selectedRowsUser
        .filter((u) => !u.__DELETED__)
        .map((u) => ({
        contactId: String(u.Id),
        lastName: String(u.LastName),
        firstName: String(u.FirstName),
        companyName: String(u.CompanyName),
        emailAddress: String(u.EmailAddress),
        roleId: '',
    }))
        .map((u) => {
        return Object.assign(Object.assign({}, u), { roleId: String(roles) });
    }), [roles, selectedRowsUser]);
    const back = () => {
        const currentSteps = currentStep - 1;
        if (currentStep === 2) {
            setSelectedRowsRoles([]);
        }
        setCurrentStep(currentSteps);
    };
    const forward = () => {
        const currentSteps = currentStep + 1;
        setCurrentStep(currentSteps);
    };
    const handleDataChanged = React.useCallback((args) => {
        const changedData = Object.values(args.changedData).sort();
        const rows = args.data.filter((row) => !changedData.some((r) => r.contactId === row.contactId));
        setSelectedRolesUser([...rows, ...changedData]);
    }, []);
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const showError = () => {
            setIsSubmit(false);
            toastError(React.createElement(ToastMessage, { title: "Error", message: "Something went wrong" }));
        };
        const finalRolesUser = selectedRolesUser.filter((r) => !r.__DELETED__);
        yield apiProvider
            .postData(`${baseUrl}/api/UserRequest`, {
            addTasks: finalRolesUser,
            updateTasks: [],
            deleteTasks: [],
        }, (_a = api.requestInit) !== null && _a !== void 0 ? _a : {})
            .then((r) => {
            if (r.errorCount && r.errorCount !== 0) {
                showError();
            }
            else {
                setIsSubmit(false);
                setCurrentStep(0);
                setSelectedRowsUser([]);
                setSelectedRowsRoles([]);
                setSelectedRolesUser([]);
                toastSuccess(React.createElement(ToastMessage, { title: "Successful", message: "Contact(s) sent for Approval/Rejection" }), {
                    onClose: () => {
                        navigate(-1);
                    },
                });
            }
        })
            .catch(showError);
    });
    const handleSubmit = () => {
        setIsSubmit(true);
        onSubmit();
    };
    const isDisabled = React.useMemo(() => {
        if (currentStep === 0) {
            if (selectedRowsUser.length === 0)
                return true;
            return selectedRowsUser.length > 0 && selectedRowsUser.every((u) => u.__DELETED__ === true);
        }
        if (currentStep === 1)
            return selectedRowsRoles.length === 0;
        if (currentStep === 2) {
            if (selectedRolesUser.length === 0)
                return true;
            return selectedRolesUser.length > 0 && selectedRolesUser.every((u) => u.__DELETED__ === true);
        }
        return true;
    }, [currentStep, selectedRowsRoles.length, selectedRowsUser, selectedRolesUser]);
    React.useEffect(() => {
        if (currentStep === 1) {
            setWizardisDirty(true);
        }
        if (currentStep === 2) {
            setSelectedRolesUser(users);
        }
    }, [currentStep, users, setWizardisDirty]);
    const gridPicklist = React.useMemo(() => new GridPicklistManager({
        picklistGetter: () => picklistGetter(false, picklistField.picklistId),
        itemGetter: (picklist, params) => {
            const newParams = Object.assign({}, params);
            if (!newParams.ids) {
                // cannot add filters to ids
                if (!newParams.filters)
                    newParams.filters = [];
                newParams.filters.push(`([TypeId] = 'PORTFOLIOACCESS' || [TypeId] = 'SYSTEMADMIN')`);
            }
            return picklistItemGetter(picklist, newParams);
        },
    }), [picklistGetter, picklistItemGetter]);
    const { wrappedFields: [roleIdField], frameworkComponents, } = React.useMemo(() => transformPicklistGridFieldConfigurations([
        {
            field: 'roleId',
            displayNameRule: 'Roles',
            editableRule: 'true',
            width: 500,
            picklistField,
        },
    ], () => gridPicklist, evaluate), [evaluate, gridPicklist]);
    const userColumns = React.useMemo(() => [
        { field: 'firstName', headerName: 'First Name', width: 125 },
        { field: 'lastName', displayNameRule: 'Last Name', width: 125 },
        { field: 'companyName', displayNameRule: 'Company', width: 150 },
        { field: 'emailAddress', displayNameRule: 'Email Address', width: 300 },
        roleIdField,
        { field: 'contactId', displayNameRule: 'ContactId', visibleRule: 'false' },
    ], [roleIdField]);
    return (React.createElement("div", { className: "userWizard" },
        React.createElement("div", { className: "pageHeading" },
            React.createElement("span", null, "Request User Access")),
        React.createElement(ProgressBar, { steps: steps, currentStep: currentStep }),
        React.createElement("div", { className: "navigationPanel" },
            React.createElement(NavigationPanel, { disabled: isDisabled, curentScreen: currentStep, back: back, forward: forward, handleSubmit: handleSubmit })),
        isSubmit && (React.createElement("div", { className: "loading" },
            React.createElement(Spinner, { label: "Processing...", ariaLive: "assertive", labelPosition: "right" }))),
        React.createElement("div", { className: "userSteps" },
            currentStep === 0 && (React.createElement(TwoGrids, { listViewId: "UserContact", previousData: selectedRowsUser, setData: setSelectedRowsUser, contactIds: contactIds })),
            currentStep === 1 && (React.createElement("div", { className: "Roles" },
                React.createElement(GridScreenWrapper, { listViewId: "ROEN_AllRoles", filters: [`([TypeId] = 'PORTFOLIOACCESS' || [TypeId] = 'SYSTEMADMIN')`], onSelectionChanges: setSelectedRowsRoles, ServiceDrivenViewSet: ServiceDrivenViewSet }))),
            currentStep === 2 && (React.createElement("div", { className: "userRolegrid" },
                React.createElement(Grid, { onSubmit: handleSubmit, isEditingDefault: true, suppressEditToggle: true, suppressAddRowButton: true, suppressDeleteButton: false, suppressClearFilter: true, suppressExcelExport: true, suppressRowSpacing: true, suppressDeleteConfirmationModal: true, suppressColumnFilters: true, suppressFullScreen: true, data: selectedRolesUser, fields: userColumns, onChangeData: handleDataChanged, stopEditingWhenCellsLoseFocus: true, frameworkComponents: frameworkComponents }))))));
};
export default UserWizard;
