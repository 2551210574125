import React from 'react';
import { useApplicationContext } from '@samc/screen-config-api';
import { useApiContext } from '@samc/user-management-api';
import { ToastMessage, toastError } from '@samc/react-ui-core';
import { getUnlockUserContent } from '../../static/UnlockUserContent';
import { Status, ViewID } from '../../static/UserType';
import { unlockSelectedUsers, warnNoInActiveUsers } from './UnlockUsers.utils';
const UnlockUsers = ({ action, users, updateAction, refreshViewSet }) => {
    const { viewId } = action;
    const api = useApiContext();
    const { currentUser, Entitlements } = useApplicationContext();
    React.useEffect(() => {
        const isGlobal = viewId === ViewID.UnlockGlobalUsers;
        const { NOTIFICATIONS } = getUnlockUserContent(users.length > 1);
        const lockedUsers = users.filter(({ OktaStatus }) => OktaStatus === Status.OktaLockedOut);
        if (!currentUser.hasEntitlement(Entitlements.UpdateUsers)) {
            toastError(React.createElement(ToastMessage, { title: "Unlock User(s)", message: "You are not authorized to perform this action. Please contact your administrator if you believe this is an error." }));
            updateAction(undefined);
        }
        else if (lockedUsers.length > 0) {
            unlockSelectedUsers(lockedUsers, isGlobal, api.requestInit, updateAction, refreshViewSet);
        }
        else {
            warnNoInActiveUsers(updateAction, NOTIFICATIONS);
        }
    }, [users, viewId, api.requestInit, updateAction, refreshViewSet, currentUser, Entitlements.UpdateUsers]);
    return React.createElement(React.Fragment, null);
};
export default UnlockUsers;
