import { TooltipHost } from '@fluentui/react';
import { Button } from '@samc/react-ui-core';
import React from 'react';
const labels = {
    HEADER_TITLE: 'Disable User(s)',
    Back_BTN: 'Back',
    Continue_BTN: 'Continue',
    Submit_BTN: 'Submit Request',
};
export const NavigationPanel = (prop) => {
    const { disabled, curentScreen, forward, back, handleSubmit } = prop;
    return (React.createElement(React.Fragment, null,
        curentScreen === 0 && (React.createElement("div", null,
            React.createElement(TooltipHost, { content: "Select one or more contacts to continue to the next step" },
                React.createElement(Button, { buttonType: "primary", text: labels.Continue_BTN, onClick: forward, disabled: disabled })))),
        curentScreen === 1 && (React.createElement("div", null,
            React.createElement(Button, { buttonType: "secondary", text: labels.Back_BTN, onClick: back }),
            React.createElement("span", { className: "buttons" }),
            React.createElement(TooltipHost, { content: "Select one or more roles to continue to the next step" },
                React.createElement(Button, { buttonType: "primary", text: labels.Continue_BTN, onClick: forward, disabled: disabled })))),
        curentScreen === 2 && (React.createElement("div", null,
            React.createElement(Button, { buttonType: "secondary", text: labels.Back_BTN, onClick: back }),
            React.createElement("span", { className: "buttons" }),
            React.createElement(Button, { buttonType: "primary", text: labels.Submit_BTN, onClick: handleSubmit, disabled: disabled })))));
};
export default NavigationPanel;
