import React from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getTabRedirectString, ViewSetSubtabChangedEvent } from '@samc/screen-config-core';
import { MyAccount } from './MyAccountModal';

const MyAccountViewSet = (): React.ReactElement => {
  const originalLocation = useLocation();
  const { tabId: urlTabId, subtabId: urlSubtabId } = useParams();

  const navigate = useNavigate();

  const onTabIdChanged = React.useCallback(
    (newId: string, userRequested?: boolean) => {
      if (newId === urlTabId) return; // do nothing if id doesn't change

      navigate(
        getTabRedirectString({
          originalLocation,
          oldTabId: urlTabId,
          oldSubtabId: urlSubtabId,
          newTabId: newId,
          newSubtabId: undefined, // should clear it on tab change
        }),
        { replace: !userRequested },
      );
    },
    [navigate, originalLocation, urlSubtabId, urlTabId],
  );

  const onSubtabIdChanged = React.useCallback(
    (ev: ViewSetSubtabChangedEvent) => {
      const { newId, userRequested } = ev;

      if (newId === urlSubtabId) return; // do nothing if id doesn't change

      navigate(
        getTabRedirectString({
          originalLocation,
          oldTabId: urlTabId,
          oldSubtabId: urlSubtabId,
          newTabId: urlTabId,
          newSubtabId: newId,
        }),
        { replace: !userRequested },
      );
    },
    [navigate, originalLocation, urlSubtabId, urlTabId],
  );

  return (
    <MyAccount
      onTabIdChanged={onTabIdChanged}
      onSubtabChanged={onSubtabIdChanged}
      tabId={urlTabId}
      subtabId={urlSubtabId}
    />
  );
};

export default MyAccountViewSet;
