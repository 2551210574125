var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid } from '@samc/react-ui-grid';
import { useQBQuery } from '@samc/screen-config-api';
import React from 'react';
import { Status } from '../../static/UserType';
import { GridSearch } from './GridSearch';
const noop = () => undefined;
export const TwoGrids = (prop) => {
    const { listViewId, previousData, contactIds, setData } = prop;
    const columns = [
        { field: 'FirstName', headerName: 'First Name' },
        { field: 'LastName', displayNameRule: 'Last Name' },
        { field: 'CompanyName', displayNameRule: 'Company' },
        { field: 'EmailAddress', displayNameRule: 'Email Address' },
        { field: 'Id', displayNameRule: 'ContactId', visibleRule: 'false' },
    ];
    const contactIdsArray = contactIds === null || contactIds === void 0 ? void 0 : contactIds.split(',');
    const ids = React.useMemo(() => contactIdsArray === null || contactIdsArray === void 0 ? void 0 : contactIdsArray.map((x) => `'${x}' `).join(','), [contactIdsArray]);
    const adhocListViewMembers = React.useMemo(() => [
        {
            scalarExpression: `[FirstName]`,
            scalarDisplayName: 'FirstName',
        },
        {
            scalarExpression: '[LastName]',
            scalarDisplayName: 'LastName',
        },
        {
            scalarExpression: `[Id]`,
            scalarDisplayName: 'Id',
        },
        {
            scalarExpression: '[EmailAddress]',
            scalarDisplayName: 'EmailAddress',
        },
        {
            scalarExpression: `[CompanyName]`,
            scalarDisplayName: 'CompanyName',
        },
    ], []);
    const qbQuery = useQBQuery();
    // to get data of selected contacts from companycatlogue
    React.useEffect(() => {
        function fetchQbData() {
            return __awaiter(this, void 0, void 0, function* () {
                const loadViewSetData = () => __awaiter(this, void 0, void 0, function* () {
                    return qbQuery({
                        domainId: listViewId,
                        primaryViewId: '-1',
                        filterIds: [],
                        paging: { start: 0, stop: 1000 },
                        adhocListViewMembers,
                        sorting: {},
                        summaryMode: false,
                        adhocFilter: {
                            filterName: 'adhoc',
                            advancedInd: true,
                            expressionLang: 'Centric',
                            advancedExpression: `(([Id] IN (${ids})) AND ([UserCreationDate] IS NULL) AND Lower(Coalesce([UserStatus],'')) NOT IN ('${Status.Pending.toLowerCase()}','${Status.Active.toLowerCase()}'))`,
                        },
                    });
                });
                if (contactIds) {
                    const result = yield loadViewSetData();
                    setData([...result.Data]);
                }
            });
        }
        if (previousData.length <= 0) {
            fetchQbData();
        }
        // qbQuery not included in this since it is a function
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleDataChanged = React.useCallback((args) => {
        const changedData = Object.values(args.changedData);
        const rows = previousData.filter((row) => !changedData.some((r) => r.Id === row.Id));
        setData([...changedData, ...rows]);
    }, [setData, previousData]);
    return (React.createElement("div", { className: "twogrid" },
        React.createElement("div", null,
            React.createElement("span", { className: "gridHeading findHeading" }, "FIND CONTACTS"),
            React.createElement(GridSearch, { listViewId: listViewId, rightGridRows: previousData, setRightGridRows: setData })),
        React.createElement("div", { className: "basegrid" },
            React.createElement("span", { className: "gridHeading selectHeading" }, "SELECTED CONTACTS"),
            React.createElement(Grid, { onSubmit: noop, isEditingDefault: false, suppressEditToggle: true, suppressAddRowButton: true, suppressDeleteButton: false, suppressClearFilter: true, suppressExcelExport: true, suppressRowSpacing: true, suppressDeleteConfirmationModal: true, suppressColumnFilters: true, suppressFullScreen: true, data: previousData, fields: columns, onChangeData: handleDataChanged, stopEditingWhenCellsLoseFocus: true }))));
};
export default TwoGrids;
