/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { RoutingWrapper, useHeaderContext } from '@samc/react-ui-history';
import { useApiContext, apiProvider } from '@samc/user-management-api';
import { ViewSetAction } from '@samc/screen-config-api';
import './MyAccount.scss';
import { ActionOverrideContextProvider, ViewSetSubtabChangedEvent } from '@samc/screen-config-core';
import { overridenViewids } from '../ViewSetPage/overrides';
import { ViewSetMain } from '../ViewSetPage/ViewSetPage';

interface GetMeRecords {
  userId: string;
  externalId: string;
}

export interface MyAccountProps {
  onTabIdChanged?: (newId: string, userRequested?: boolean) => void;
  onSubtabChanged?: (ev: ViewSetSubtabChangedEvent) => void;
  tabId?: string;
  subtabId?: string;
}

export const MyAccount = (props: MyAccountProps): React.ReactElement => {
  const { onTabIdChanged, tabId, subtabId, onSubtabChanged } = props;

  const { tabs } = useHeaderContext();
  const api = useApiContext();

  const [userId, setUserId] = React.useState<string>('');
  const [showConfigModal, setShowConfigModal] = React.useState<boolean>(false);
  const [triggeredAction, updateAction] = React.useState<ViewSetAction>();

  React.useEffect((): void => {
    // call api
    async function fetchMyAPI(): Promise<void> {
      const response = await apiProvider.getData<GetMeRecords>(
        `${api.UserManagementApi}/api/User/me`,
        api.requestInit ?? {},
      );
      setUserId(response.userId);
    }

    fetchMyAPI();
  }, []);

  const onTabIndexChanged = React.useCallback(
    (newIndex: number, userRequested: boolean) => {
      if (!tabs || !onTabIdChanged) return;

      const tab = tabs[newIndex];
      if (!tab) return;

      onTabIdChanged(tab.id, userRequested);
    },
    [onTabIdChanged, tabs],
  );

  return (
    <ActionOverrideContextProvider
      value={overridenViewids.reduce((o, v) => ({ ...o, [v]: (item: ViewSetAction): void => updateAction(item) }), {})}
    >
      <RoutingWrapper onTabChanged={onTabIndexChanged}>
        <ViewSetMain
          triggeredAction={triggeredAction}
          updateAction={updateAction}
          showConfigModal={showConfigModal}
          setShowConfigModal={setShowConfigModal}
          userId={userId}
          onTabChanged={onTabIdChanged}
          tabId={tabId}
          subtabId={subtabId}
          onSubtabChanged={onSubtabChanged}
        />
      </RoutingWrapper>
    </ActionOverrideContextProvider>
  );
};

export default MyAccount;
