import React from 'react';
import { Modal } from '@fluentui/react';
import { SitusHeader } from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import './DisableUsers.scss';
import { MultilineTextEntryField } from '@samc/react-ui-form';
import { Button, ControlBar, toastError, ToastMessage } from '@samc/react-ui-core';
import { useApplicationContext } from '@samc/screen-config-api';
import { useApiContext } from '@samc/user-management-api';
import { getDisableUserContent } from '../../static/DisableUserContent';
import { Status } from '../../static/UserType';
import { onSubmit } from './DisableUsers.utils';
export const DisableUsers = ({ users, isGlobal, updateAction, refreshViewSet, }) => {
    const [disableReason, updateReason] = React.useState('');
    const [showModal, toggleModal] = React.useState(true);
    const api = useApiContext();
    const activeUsers = [];
    const inactiveUsers = [];
    const { currentUser, Entitlements } = useApplicationContext();
    users.forEach((u) => {
        var _a;
        if (((_a = u.StatusTypeId) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === Status.Active.toLowerCase())
            activeUsers.push(u);
        else
            inactiveUsers.push(u);
    });
    const { BUTTONS, DISABLE_REASONS, LABELS } = React.useMemo(() => getDisableUserContent(), []);
    const closeModal = () => {
        toggleModal(false);
        updateAction(undefined);
    };
    const handleSubmit = () => {
        toggleModal(false);
        onSubmit(activeUsers, disableReason, isGlobal, api.requestInit, updateAction, refreshViewSet);
    };
    const handleChange = (newValue) => {
        updateReason(newValue);
    };
    const getEmailList = (userList, listLabel) => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "modal-text" },
            listLabel,
            " (",
            userList.length,
            ")"),
        React.createElement("ul", { className: "user-list" }, userList.map(({ EmailAddress }) => (React.createElement("li", { key: EmailAddress }, EmailAddress))))));
    if (!currentUser.hasEntitlement(Entitlements.DisableUsers)) {
        toastError(React.createElement(ToastMessage, { title: "Disable User", message: "You are not authorized to perform this action. Please contact your administrator if you believe this is an error." }));
        updateAction(undefined);
    }
    return (React.createElement(Modal, { isOpen: showModal, onDismiss: closeModal, isBlocking: false, className: "userModals" },
        React.createElement("div", { className: "ag-theme-balham ag-theme-centricity disable-user-modal", style: { display: 'block', width: '66vw' } },
            React.createElement(SitusHeader, { title: LABELS.DISABLE_USERS_TITLE + (isGlobal ? 'Globally' : '') },
                React.createElement(ControlBar, { cancel: closeModal, cancelDisabled: false, additionalControls: React.createElement(Button, { text: BUTTONS.DISABLE_USERS, disabled: disableReason.trim() === '' || disableReason.length > 999, onClick: handleSubmit }) })),
            React.createElement("div", { className: "disable-modal-content" },
                getEmailList(users, LABELS.SELECTED_LIST),
                inactiveUsers.length > 0 && getEmailList(activeUsers, LABELS.ACTIVE_LIST),
                inactiveUsers.length > 0 && getEmailList(inactiveUsers, LABELS.ALREADY_DISABLED_LIST),
                React.createElement("div", { className: "modal-text" },
                    LABELS.DISABLE_WARNING_FOR_CLIENT,
                    isGlobal ? 'all instances of VMS Next.' : `VMS Next for ${activeUsers[0].ClientName}.`),
                React.createElement("div", { className: "modal-text" }, LABELS.DISABLE_USER_PROVIDE_REASON),
                activeUsers.length > 0 && React.createElement("div", { className: "modal-text" }, LABELS.DISABLE_MULTIPLE_USERS_REASONS_MSG),
                React.createElement("div", { className: "modal-buttons" }, DISABLE_REASONS.map((r) => (React.createElement(Button, { key: r[0], text: r[0], disabled: false, onClick: () => updateReason(r[1]) })))),
                React.createElement(MultilineTextEntryField, { name: "Reason for disable", value: disableReason, onChange: handleChange, onBlur: () => true, hasError: disableReason.trim() === '' }),
                disableReason.length > 999 && (React.createElement("div", { className: "modal-text modal-error" }, "Disable reason cannot exceed 1000 characters.")),
                React.createElement("div", null)))));
};
export default DisableUsers;
