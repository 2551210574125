var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-unused-expressions */
import React from 'react';
import { toastError, ToastMessage, toastSuccess, toastWarning } from '@samc/react-ui-core';
import { apiProvider } from '@samc/user-management-api';
import { getUnlockUserContent } from '../../static/UnlockUserContent';
const baseUrl = `${window.location.origin}/_apis/user-management`;
export const unlockSelectedUsers = (lockedUsers, isGlobal, requestInit, updateAction, refreshViewSet) => __awaiter(void 0, void 0, void 0, function* () {
    const { NOTIFICATIONS } = getUnlockUserContent(lockedUsers.length > 1);
    const updatedUsers = lockedUsers.map((u) => {
        const user = Object.assign({}, u);
        delete user.UserID;
        delete user.primaryKey;
        delete user.OrderBy;
        delete user.NewEmailAddress;
        delete user.ConfirmNewEmailAddress;
        delete user.companyNotChanged;
        delete user.EmailAddressChangeReason;
        return Object.assign({}, user);
    });
    const showError = (msg = NOTIFICATIONS.MESSAGE.UNLOCK_USERS_FAIL) => {
        toastError(React.createElement(ToastMessage, { title: NOTIFICATIONS.LABEL.UNLOCK_USERS, message: msg }));
    };
    yield apiProvider
        .putData(`${baseUrl}/api/UserAction/UnlockUsers`, {
        payload: {
            isClientAdmin: !isGlobal,
            isGlobalAdmin: isGlobal,
            users: updatedUsers,
        },
    }, requestInit !== null && requestInit !== void 0 ? requestInit : {})
        .then((r) => {
        if (r.errorCount && r.errorCount !== 0) {
            showError(`${r.error}`);
        }
        else {
            toastSuccess(React.createElement(ToastMessage, { title: NOTIFICATIONS.LABEL.UNLOCK_USERS, message: NOTIFICATIONS.MESSAGE.UNLOCK_USERS_SUCCESS }));
        }
        refreshViewSet && setTimeout(refreshViewSet, 1000);
    })
        .catch(showError);
    updateAction(undefined);
});
export const warnNoInActiveUsers = (updateAction, NOTIFICATIONS) => {
    setTimeout(updateAction.bind(undefined, undefined), 1000);
    toastWarning(React.createElement(ToastMessage, { title: NOTIFICATIONS.LABEL.UNLOCK_USERS, message: NOTIFICATIONS.MESSAGE.UNLOCK_USERS_NO_SELECTION }));
};
export const globalUserAdhocListViewMembers = [
    {
        scalarExpression: '[StatusTypeId]',
        scalarDisplayName: 'StatusTypeId',
    },
    {
        scalarExpression: '[EmailAddress]',
        scalarDisplayName: 'EmailAddress',
    },
    {
        scalarExpression: '[LastName]',
        scalarDisplayName: 'LastName',
    },
    {
        scalarExpression: '[FirstName]',
        scalarDisplayName: 'FirstName',
    },
    {
        scalarExpression: '[CreatedOn]',
        scalarDisplayName: 'CreatedOn',
    },
    {
        scalarExpression: '[CreatedName]',
        scalarDisplayName: 'CreatedName',
    },
    {
        scalarExpression: '[LastLogin]',
        scalarDisplayName: 'LastLogin',
    },
    {
        scalarExpression: '[UpdatedOn]',
        scalarDisplayName: 'UpdatedOn',
    },
    {
        scalarExpression: '[UpdatedName]',
        scalarDisplayName: 'Updated By',
    },
    {
        scalarExpression: '[DisabledOn]',
        scalarDisplayName: 'DisabledOn',
    },
    {
        scalarExpression: '[InActivationReason]',
        scalarDisplayName: 'InActivationReason',
    },
    {
        scalarExpression: '[ProviderName]',
        scalarDisplayName: 'ProviderName',
    },
    {
        scalarExpression: '[OktaStatus]',
        scalarDisplayName: 'OktaStatus',
    },
];
export default {
    globalUserAdhocListViewMembers,
    unlockSelectedUsers,
    warnNoInActiveUsers,
};
