export var Status;
(function (Status) {
    Status["Active"] = "Active";
    Status["Inactive"] = "Inactive";
    Status["Pending"] = "Pending";
    Status["OktaLockedOut"] = "LOCKED_OUT";
    Status["OktaActive"] = "ACTIVE";
    Status["OktaSuspended"] = "SUSPENDED";
    Status["OktaProvisioned"] = "PROVISIONED";
    Status["OktaDeProvisioned"] = "DEPROVISIONED";
})(Status || (Status = {}));
export var FormViews;
(function (FormViews) {
    FormViews["UserDetail"] = "UserDetail";
    FormViews["GlobalUserDetail"] = "GlobalUserDetail";
})(FormViews || (FormViews = {}));
export var ViewID;
(function (ViewID) {
    ViewID["ChangeEmail"] = "ChangeEmail";
    ViewID["DisableUser"] = "DisableUser";
    ViewID["DisableGlobalUser"] = "DisableGlobalUser";
    ViewID["DisableUsers"] = "DisableUsers";
    ViewID["DisableGlobalUsers"] = "DisableGlobalUsers";
    ViewID["ReactivateUser"] = "ReactivateUser";
    ViewID["ReactivateGlobalUser"] = "ReactivateGlobalUser";
    ViewID["ReactivateUsers"] = "ReactivateUsers";
    ViewID["ReactivateGlobalUsers"] = "ReactivateGlobalUsers";
    ViewID["RequestUserAccess"] = "RequestUserAccess";
    ViewID["RejectUsers"] = "RejectUsers";
    ViewID["ApproveUsers"] = "ApproveUsers";
    ViewID["AddRoles"] = "AddRoles";
    ViewID["RemoveRoles"] = "RemoveRoles";
    ViewID["UserPassword"] = "UserPassword";
    ViewID["IdentityUserPassword"] = "IdentityUserPassword";
    ViewID["NonIdentityUserPassword"] = "NonIdentityUserPassword";
    ViewID["HelpTopics"] = "HelpTopics";
    ViewID["UnlockUser"] = "UnlockUser";
    ViewID["UnlockUsers"] = "UnlockUsers";
    ViewID["UnlockGlobalUser"] = "UnlockGlobalUser";
    ViewID["UnlockGlobalUsers"] = "UnlockGlobalUsers";
})(ViewID || (ViewID = {}));
