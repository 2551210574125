export const getReactivateUserContent = (multiple = false) => {
    const label = multiple ? `users` : `user`;
    return {
        NOTIFICATIONS: {
            LABEL: {
                REACTIVATE_USERS: `Reactivate ${label}`,
            },
            MESSAGE: {
                REACTIVATE_USERS_SUCCESS: `Selected user(s) were successfully reactivated`,
                REACTIVATE_USERS_FAIL: `Something went wrong`,
                REACTIVATE_USERS_NO_SELECTION: `No inactive users selected`,
            },
        },
    };
};
export default {
    reactivateUserContent: getReactivateUserContent(false),
};
