export const getDisableUserContent = () => {
    // const label = multiple ? `users` : `user`;
    // const capitalizedLabel = multiple ? `Users` : `User`;
    return {
        BUTTONS: {
            CANCEL_DISABLE: `Cancel`,
            DISABLE_USERS: `Disable User(s)`,
        },
        LABELS: {
            DISABLE_USERS_TITLE: `Disable User(s) `,
            SELECTED_LIST: `Selected user(s)`,
            ACTIVE_LIST: `User(s) to be disabled`,
            ALREADY_DISABLED_LIST: `Already disabled user(s)`,
            DISABLE_WARNING_FOR_CLIENT: `You are about to disable the selected user(s) from accessing `,
            DISABLE_USER_PROVIDE_REASON: `Please provide a reason the user(s) are being disabled. `,
            DISABLE_MULTIPLE_USERS_REASONS_MSG: `You can only enter one disable reason for the group of users selected. If the users are being disabled for different reasons, then each should be disabled separately so you can enter different reasons for each user.`,
        },
        NOTIFICATIONS: {
            LABEL: {
                DISABLE_USERS: `Disable user(s)`,
            },
            MESSAGE: {
                DISABLE_USERS_SUCCESS: `Selected User(s) were successfully disabled`,
                DISABLE_USERS_FAIL: `Something went wrong`,
                DISABLE_USERS_NO_SELECTION: `No active users selected`,
                GLOBALLY_DISABLED: 'Users Inactivated Globally. Cannot Reactivated Locally.',
            },
        },
        DISABLE_REASONS: [
            ['Inactive 90 Days', 'The user has not logged in at least 90 days.'],
            ['Left Company', 'The user no longer works at the company. The account should be permanently disabled.'],
        ],
    };
};
export default {
    disableUserContent: getDisableUserContent(),
};
